import { StyleSheet } from '@codebox-team/web/utils'

export const StyledHeaderComponentLibWrapperComponent = StyleSheet.create(
  'div',
)`
  .header {
    height: 72px;
    box-shadow: 0px 2px 6px 0px rgba(46, 58, 79, 0.25);

    > div {
      min-height: 72px;
    }
  }
`
