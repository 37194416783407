import { useEffect } from 'react'

import { ITermsAndPoliciesComponentProps } from '@/presentation/components/Data/TermsAndPolicies/TermsAndPoliciesComponent.types'
import {
  IPlatformTermsConditions,
  IPlatformTermsConditionsDataUrl,
} from '@codebox-team/core'
import { usePlatformGetTermsConditionsByReference } from '@customerportal/core'

export const useTermsAndPoliciesScreenRules = ({
  country,
}: ITermsAndPoliciesComponentProps) => {
  const getPoliciesByReference = usePlatformGetTermsConditionsByReference()
  const getTermsByReference = usePlatformGetTermsConditionsByReference()

  useEffect(() => {
    getPoliciesByReference.handleFetch({
      externalReference: country,
      purpose: 2,
    })

    getTermsByReference.handleFetch({
      externalReference: country,
      purpose: 1,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const terms =
    getTermsByReference.data as IPlatformTermsConditions<IPlatformTermsConditionsDataUrl>
  const policies =
    getPoliciesByReference.data as IPlatformTermsConditions<IPlatformTermsConditionsDataUrl>

  const isTermsBusy = getTermsByReference.isWaiting
  const isPoliciesBusy = getPoliciesByReference.isWaiting

  return {
    terms,
    policies,
    isTermsBusy,
    isPoliciesBusy,
  }
}
