import {
  MenuItemComponent,
  SelectComponent,
} from '@codebox-team/web/components'
import { StyleSheet } from '@codebox-team/web/utils'

export const StyledSelectComponent = StyleSheet.create(SelectComponent)`
  padding: 0px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  background-color: ${({ theme }) => theme.palette.common.white};

  > div {
    padding: 13px;
  }

  fieldset {
    border: none;
  }
`

export const StyledMenuItemComponent = StyleSheet.create(MenuItemComponent)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`
