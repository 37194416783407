import React from 'react'

import { AirLiquideLogoComponent } from '@/presentation/components/Data/AirLiquideLogo/AirLiquideLogoComponent'
import {
  StyledPublicContentWrapper,
  StyledAppLayoutPublicProviderWrapper,
  StyledPublicHeaderContentWrapper,
} from '@/presentation/components/Providers/AppLayout/Public/AppLayoutPublicProvider.styles'
import { IAppLayoutPublicProviderProps } from '@/presentation/components/Providers/AppLayout/Public/AppLayoutPublicProvider.types'
import { HeaderComponent } from '@/presentation/components/Surfaces/Header/HeaderComponent'

export const AppLayoutPublicProvider: React.FC<
  IAppLayoutPublicProviderProps
> = ({ testID, children }) => (
  <StyledAppLayoutPublicProviderWrapper data-testid={testID}>
    <HeaderComponent>
      <StyledPublicHeaderContentWrapper>
        <AirLiquideLogoComponent />
      </StyledPublicHeaderContentWrapper>
    </HeaderComponent>

    <StyledPublicContentWrapper>{children}</StyledPublicContentWrapper>
  </StyledAppLayoutPublicProviderWrapper>
)
