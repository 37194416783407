import { StyleSheet } from '@codebox-team/web/utils'

export const StyledFormComponent = StyleSheet.create('form')`
  .container {
    div {
      padding: 0 8px;

      input {
        padding-left: 20px;
        letter-spacing: 1.2rem;
        display: flex;

        &::placeholder {
          letter-spacing: 0;
        }
      }
    }
  }

  .submitButton {
    margin-bottom: 8px;
  }

  .divider {
    background-color: ${({ theme }) => theme.palette.divider};
    margin: 20px 0;
  }
`
