/* eslint-disable quotes */
import { AutocompleteComponent } from '@codebox-team/web/components'
import { StyleSheet } from '@codebox-team/web/utils'
import { IRegion } from '@customerportal/core'

export const StyledAutocompleteComponent = StyleSheet.create(
  AutocompleteComponent,
  { shouldForwardProp: prop => prop !== '$region' },
)<{
  $region: IRegion['locale']
}>`
  display: flex;
  align-items: center;
  div {
    height: 100%;

    > label[data-shrink='false'] {
      font-size: 14px;
      transform: translate(14px, 18px) scale(1);
    }
  }

  > div > div::before {
    content: '';

    ${({ $region }) =>
      ({
        'pt-BR': `
          width: 24px;
          height: 24px;
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='BR'%3E%3Crect id='Mask' y='3.42859' width='24' height='17.1429' rx='2' fill='white' /%3E%3Cmask id='mask0_169_1082' style='mask-type:luminance' maskUnits='userSpaceOnUse' x='0' y='3' width='24' height='18'%3E%3Crect id='Mask_2' y='3.42859' width='24' height='17.1429' rx='2' fill='white' /%3E%3C/mask%3E%3Cg mask='url(%23mask0_169_1082)'%3E%3Crect id='Mask Copy' y='3.42859' width='24' height='17.1429' fill='%2305AB41' /%3E%3Cpath id='Rectangle 60' fill-rule='evenodd' clip-rule='evenodd' d='M3.15066 12.5597C2.74466 12.297 2.74466 11.703 3.15066 11.4403L11.6378 5.94864C11.8582 5.80603 12.1417 5.80603 12.3621 5.94864L20.8492 11.4403C21.2552 11.703 21.2552 12.297 20.8492 12.5597L12.3621 18.0514C12.1417 18.194 11.8582 18.194 11.6378 18.0514L3.15066 12.5597Z' fill='%23FDD216' /%3E%3C/g%3E%3Cg id='Oval 2'%3E%3Cpath id='Mask_3' fill-rule='evenodd' clip-rule='evenodd' d='M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z' fill='%23053087' /%3E%3Cmask id='mask1_169_1082' style='mask-type:luminance' maskUnits='userSpaceOnUse' x='8' y='8' width='8' height='8'%3E%3Cpath id='Mask_4' fill-rule='evenodd' clip-rule='evenodd' d='M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z' fill='white' /%3E%3C/mask%3E%3Cg mask='url(%23mask1_169_1082)'%3E%3Cpath id='Line' d='M7.52546 10.9545C8.15349 10.4459 10.1801 10.9277 12.0718 11.4323C13.9636 11.937 15.9367 12.9947 16.439 13.6151' stroke='white' stroke-width='1.33333' stroke-linecap='square' /%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        `,
        'es-ES': `
          width: 24px;
          height: 24px;
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='AR'%3E%3Crect id='Mask' x='0.25' y='3.67859' width='23.5' height='16.6429' rx='1.75' fill='white' stroke='%23F5F5F5' stroke-width='0.5' /%3E%3Cmask id='mask0_169_1088' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='3' width='24' height='18'%3E%3Crect id='Mask_2' x='0.25' y='3.67859' width='23.5' height='16.6429' rx='1.75' fill='white' stroke='white' stroke-width='0.5' /%3E%3C/mask%3E%3Cg mask='url(%23mask0_169_1088)'%3E%3Cpath id='Rectangle 2' fill-rule='evenodd' clip-rule='evenodd' d='M0 9.14287H24V3.42859H0V9.14287Z' fill='%2388BBE8' /%3E%3Cpath id='Rectangle 2_2' fill-rule='evenodd' clip-rule='evenodd' d='M0 20.5715H24V14.8572H0V20.5715Z' fill='%2388BBE8' /%3E%3Cpath id='Oval 1' d='M11.9999 14.0476C13.1308 14.0476 14.0476 13.1309 14.0476 12C14.0476 10.8691 13.1308 9.95237 11.9999 9.95237C10.8691 9.95237 9.95231 10.8691 9.95231 12C9.95231 13.1309 10.8691 14.0476 11.9999 14.0476Z' fill='%23F4B32E' stroke='%23DB7A2C' stroke-width='0.666667' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        `,
      }[$region] ?? 'display: none;')};
  }
`

export const StyledAutocompleteOptionComponent = StyleSheet.create('li')`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const StyledAutocompleteOptionTextWrapper = StyleSheet.create('div')`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;

  > p {
    color: ${({ theme }) => theme.palette.primary.dark};
    font-size: 14px;
    line-height: normal;

    &:first-of-type {
      margin-left: 12px;
    }
  }
`
