import { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { translate } from '@/application/utils/Translate/TranslateUtil'
import { useDispatch } from '@codebox-team/redux'
import {
  cartActionStatusClear,
  ICart,
  ICartProductItem,
  ICartProducts,
} from '@customerportal/core'

export const useCartSummaryRules = (cartProducts: ICart) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const getItemLabel = ({
    description,
    quantity,
  }: Pick<ICartProductItem, 'description' | 'quantity'>) => {
    const label =
      quantity === 1
        ? translate.t('general.cylinder')
        : translate.t('general.cylinders')

    return `${quantity} - ${label} - ${description}`
  }

  const handleToggleCartSummary = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => setAnchorEl(event.currentTarget)

  const handleClose = () => setAnchorEl(null)

  const handleNavigateCart = () => {
    dispatch(cartActionStatusClear())

    history.push('/cart')
  }

  const cartProductsAdapt = useMemo<ICartProducts[]>(
    () =>
      cartProducts?.products?.map(product => ({
        ...product,
        items: product.items.filter(item => item.quantity > 0),
      })),
    [cartProducts?.products],
  )

  const totalValue = useMemo<number>(
    () =>
      cartProductsAdapt
        ?.flatMap(product =>
          product.items.map(item => item.totalValue * item.quantity),
        )
        ?.reduce((acc, current) => acc + current, 0),
    [cartProductsAdapt],
  )

  const cartQuantityItems = cartProducts?.products?.length

  return {
    anchorEl,
    cartProductsAdapt,
    cartQuantityItems,
    getItemLabel,
    handleToggleCartSummary,
    handleClose,
    handleNavigateCart,
    totalValue,
  }
}
