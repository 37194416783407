import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { useRoutesRules } from '@/application/routes/Routes.rules'
import { AppLayoutProvider } from '@/presentation/components/Providers/AppLayout/AppLayoutProvider'
import { RouteComponent } from '@codebox-team/web/components'

export const Routes: React.FC = () => {
  const { isAdmin, appRoutes, adminHomePath, isUserAuthenticated } =
    useRoutesRules()

  return (
    <Suspense fallback={false}>
      <AppLayoutProvider isPrivate={isUserAuthenticated}>
        <Switch>
          {appRoutes.map(routeProps => (
            <RouteComponent
              key={routeProps.path}
              {...routeProps}
              isUserAuthenticated={isUserAuthenticated}
              redirectPathName="/login"
            />
          ))}

          {isAdmin && (
            <Route
              path="/"
              exact
              component={() => <Redirect to={adminHomePath} />}
            />
          )}

          <RouteComponent
            component={() => (
              <Redirect to={isUserAuthenticated ? '/' : '/login'} />
            )}
          />
        </Switch>
      </AppLayoutProvider>
    </Suspense>
  )
}
