import { StyleSheet } from '@codebox-team/web/utils'

export const StyledBreadcrumbsComponentWrapper = StyleSheet.create('div')`
  width: 100%;

  nav {
    ol {
      li {
        color: ${({ theme }) => theme.palette.primary.main};
        a {
          color: ${({ theme }) => theme.palette.primary.main};
          font-size: 14px;
          font-weight: 400;
          line-height: 100%;
          text-decoration: none;
        }
      }
    }
  }
`
