import { ImageComponent } from '@/presentation/components/Data/Image/ImageComponent'
import { DialogComponent, TextComponent } from '@codebox-team/web/components'
import { StyleSheet } from '@codebox-team/web/utils'

export const StyledDialogComponent = StyleSheet.create(DialogComponent)`
  .dialog_paper {
    width: 100%;
    max-width: 916px;
    margin: 0 16px;
  }
`

export const StyledContentWrapper = StyleSheet.create('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const StyledCloseButton = StyleSheet.create(ImageComponent)`
  position: absolute;
  right: 32px;
  top: 32px;
  width: 24px;
  height: 24px;
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    right: 10px;
    top: 10px;
  }
`

export const StyledDialogFooter = StyleSheet.create('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-top: 2px solid ${({ theme }) => theme.palette.divider};
  padding: 20px 0px;
  position: sticky;
  bottom: 0;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 16px 12px;
  }

  .button {
    ${({ theme }) => theme.breakpoints.down('sm')} {
      width: 100%;
    }
  }
`

export const StyledTitle = StyleSheet.create(TextComponent)`
  color: ${({ theme }) => theme.palette.text.secondary};
  text-align: center;
  margin: 32px 0;
  line-height: 18px;
`
