import React from 'react'

import { translate } from '@/application/utils/Translate/TranslateUtil'
import FeedbackIcon from '@/presentation/assets/icons/feedback-icon.svg'
import { DialogComponent } from '@/presentation/components/Feedback/Dialog/DialogComponent'
import {
  FEEDBACK_MAX_LENGTH,
  useFeedbackContainerRules,
} from '@/presentation/containers/Feedback/FeedbackContainer.rules'
import {
  StyledFeedbackComponent,
  StyledTitleTextComponent,
} from '@/presentation/containers/Feedback/FeedbackContainer.styles'
import { IFeedbackContainerProps } from '@/presentation/containers/Feedback/FeedbackContainer.types'
import {
  BoxComponent,
  ButtonComponent,
  IconButtonComponent,
  IconComponent,
  InputTextComponent,
} from '@codebox-team/web/components'

export const FeedbackContainer: React.FC<IFeedbackContainerProps> = ({
  action,
  variant,
}) => {
  const {
    description,
    feedbackIcons,
    handleSubmitFeedback,
    handleFeedbackClear,
    isUserFeedbackBusy,
    isUserFeedbackFailure,
    isUserFeedbackSuccess,
    level,
    setValue,
  } = useFeedbackContainerRules()

  const Title = (
    <StyledTitleTextComponent>
      {translate.t('general.rate_experience')}
    </StyledTitleTextComponent>
  )

  return (
    <>
      <BoxComponent marginBottom={3}>
        {variant === 'dialog' ? (
          <BoxComponent
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            testID="Dialog title container">
            <BoxComponent alignItems="center" display="flex">
              <FeedbackIcon />
              <BoxComponent marginLeft={1}>{Title}</BoxComponent>
            </BoxComponent>

            <IconButtonComponent onClick={() => action()}>
              <IconComponent color="disabled" icon="close" />
            </IconButtonComponent>
          </BoxComponent>
        ) : (
          Title
        )}
      </BoxComponent>

      <BoxComponent display="flex" flexDirection="column" alignItems="center">
        <BoxComponent
          display="flex"
          flexDirection="column"
          alignItems="flex-start">
          <BoxComponent component="span" fontSize={14} color="gray">
            {translate.t('containers.feedback.form.level')}
          </BoxComponent>

          <BoxComponent testID="Feedback Levels">
            <StyledFeedbackComponent
              items={feedbackIcons}
              onChange={(_event, value) => setValue('level', value)}
            />
          </BoxComponent>

          <BoxComponent
            component="span"
            fontSize={14}
            color="gray"
            marginBottom={2}>
            {translate.t('containers.feedback.form.description')}
          </BoxComponent>

          <InputTextComponent
            name="description"
            placeholder={translate.t('containers.feedback.form.placeholder')}
            fullWidth
            minRows={4}
            maxRows={10}
            inputProps={{ maxLength: FEEDBACK_MAX_LENGTH }}
            multiline
            onChange={({ target: { value } }) =>
              setValue('description', value.replace('…', ''))
            }
            value={`${description}${
              description.length >= FEEDBACK_MAX_LENGTH ? '…' : ''
            }`}
            variant="outlined"
            testID="Input description"
            disabled={isUserFeedbackBusy}
          />

          <BoxComponent width="60%" marginX="auto" marginTop={4}>
            <ButtonComponent
              onClick={handleSubmitFeedback}
              variant="contained"
              fullWidth
              loading={isUserFeedbackBusy}
              disabled={isUserFeedbackBusy || !level}
              testID="Submit feedback button">
              {translate.t('containers.feedback.submit')}
            </ButtonComponent>
          </BoxComponent>
        </BoxComponent>
      </BoxComponent>

      <DialogComponent
        actions={[
          {
            type: 'primary',
            title: translate.t('general.understood'),
            onClick: () => {
              action?.()
              handleFeedbackClear()
            },
            testID: 'Feedback success action',
          },
        ]}
        disableBackdropClick
        isOpen={isUserFeedbackSuccess}
        message={translate.t('containers.feedback.dialog.success.message')}
        title={translate.t('containers.feedback.dialog.success.title')}
        type="success"
      />

      <DialogComponent
        actions={[
          {
            type: 'primary',
            title: translate.t('general.try_again'),
            onClick: onCloseDialog => {
              onCloseDialog()
              handleFeedbackClear()
            },
            testID: 'Feedback try again action',
          },
        ]}
        disableBackdropClick
        isOpen={isUserFeedbackFailure}
        message={translate.t('general.generic_error_message')}
        title={translate.t('general.warning_title')}
        type="failure"
      />
    </>
  )
}
