import React from 'react'

import { translate } from '@/application/utils/Translate/TranslateUtil'
import { ArlindoComponent } from '@/presentation/components/Data/Arlindo/ArlindoComponent'
import {
  StyledArlindoWrapper,
  StyledBannerComponentWrapper,
  StyledBannerContentWrapper,
  StyledEllipseIn,
  StyledEllipseOut,
  StyledGreeting,
  StyledGreetingMessage,
  StyledInfoWrapper,
  StyledTextWrapper,
} from '@/presentation/components/Surfaces/Banner/BannerComponent.styles'
import { IBannerProps } from '@/presentation/components/Surfaces/Banner/BannerComponent.types'

const greeting = translate.t('general.hello')
const message = translate.t('components.banner.message')

export const BannerComponent: React.FC<IBannerProps> = ({ name }) => (
  <StyledBannerComponentWrapper data-testid="Banner">
    <StyledEllipseOut>
      <StyledEllipseIn />
    </StyledEllipseOut>

    <StyledBannerContentWrapper>
      <StyledArlindoWrapper>
        <ArlindoComponent height="100%" />
      </StyledArlindoWrapper>

      <StyledInfoWrapper>
        <StyledTextWrapper>
          <StyledGreeting>
            {name ? `${greeting}, ${name}!` : `${greeting}!`}
          </StyledGreeting>
          <StyledGreetingMessage>{message}</StyledGreetingMessage>
        </StyledTextWrapper>
      </StyledInfoWrapper>
    </StyledBannerContentWrapper>
  </StyledBannerComponentWrapper>
)
