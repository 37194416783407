import React from 'react'

import { Routes } from '@/application/routes/Routes'
import { AppProvider } from '@/presentation/components/Providers/App/AppProvider'

export const App: React.FC = () => (
  <AppProvider>
    <Routes />
  </AppProvider>
)
