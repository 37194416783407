import { useEffect } from 'react'

import { TScreenProps } from '@/application/routes/Route.types'
import { useDispatch } from '@codebox-team/redux'
import {
  UserRequestVerificationCodeNamespace,
  userClear,
  userRequestVerificationCode,
  userValidateVerificationCode,
} from '@customerportal/core'

export const useLoginScreenRules = ({ history }: TScreenProps<'login'>) => {
  const dispatch = useDispatch()

  const requestVerificationCode = (
    data?: Partial<UserRequestVerificationCodeNamespace.TRequest>,
  ) => {
    dispatch(
      userRequestVerificationCode({
        country: data?.country,
        login: data?.login,
        region: data?.region,
        phonePrefix: data?.phonePrefix,
      }),
    )
  }

  const submitVerificationCode = (code: string) => {
    dispatch(userValidateVerificationCode({ validationCode: code }))
  }

  const onGetSupportHandler = () => {
    history.push('/contact-al')
  }

  useEffect(() => {
    dispatch(userClear())
  }, [dispatch])

  return {
    requestVerificationCode,
    submitVerificationCode,
    onGetSupportHandler,
  }
}
