import { AccessRights } from '@codebox-team/core'
import { useSelector } from '@codebox-team/redux'
import { selectPermission, EUserRolesTypes } from '@customerportal/core'

export const usePagePermission = (
  identifier: AccessRights.IRole['identifier'],
) =>
  useSelector(state =>
    selectPermission(state, {
      type: EUserRolesTypes.Page,
      identifier,
    }),
  )
