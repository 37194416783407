import React from 'react'

import { Regions } from '@/application/constants/Regions/RegionsConstant'
import { translate } from '@/application/utils/Translate/TranslateUtil'
import FlagAr from '@/presentation/assets/icons/flag-ar.svg'
import FlagBr from '@/presentation/assets/icons/flag-br.svg'
import { useRegionAutocompleteComponentRules } from '@/presentation/components/Inputs/RegionAutocomplete/RegionAutocompleteComponent.rules'
import {
  StyledAutocompleteComponent,
  StyledAutocompleteOptionComponent,
  StyledAutocompleteOptionTextWrapper,
} from '@/presentation/components/Inputs/RegionAutocomplete/RegionAutocompleteComponent.styles'
import { IRegionAutocompleteProps } from '@/presentation/components/Inputs/RegionAutocomplete/RegionAutocompleteComponent.types'
import { IRegion } from '@customerportal/core'

export const RegionAutocompleteComponent: React.FC<
  IRegionAutocompleteProps
> = ({ onChangeRegion, errorMessage, role, testID }) => {
  const { isShrink, onBlur, onFocus, setRegion, region } =
    useRegionAutocompleteComponentRules()

  return (
    <StyledAutocompleteComponent
      $region={region?.locale}
      options={Regions}
      inputProps={{
        name: 'region',
        label: translate.t('form.placeholders.select_your_region'),
        variant: 'outlined',
        error: !!errorMessage,
        helperText: errorMessage,
        role,
        testID: testID ?? 'Region autocomplete',
        onFocus,
        onBlur,
        InputLabelProps: {
          shrink: isShrink,
          'aria-label': 'input label',
        },
      }}
      renderOption={(props, region: IRegion) => (
        <StyledAutocompleteOptionComponent {...props}>
          {{ BR: <FlagBr />, AR: <FlagAr /> }[region.code]}
          <StyledAutocompleteOptionTextWrapper>
            <p>{region.label}</p>
            <p>(+{region.phone})</p>
          </StyledAutocompleteOptionTextWrapper>
        </StyledAutocompleteOptionComponent>
      )}
      isOptionEqualToValue={(option: string, value: string) => option === value}
      getOptionLabel={(region: IRegion) => region.label}
      fullWidth
      onChange={(_e, region: IRegion) => {
        setRegion(region)
        onChangeRegion(region)
      }}
    />
  )
}
