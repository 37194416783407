import React from 'react'

import { getArlindoImageSrc } from '@/presentation/components/Data/Arlindo/ArlindoComponent.rules'
import { IArlindoProps } from '@/presentation/components/Data/Arlindo/ArlindoComponent.types'
import { ImageComponent } from '@/presentation/components/Data/Image/ImageComponent'

export const ArlindoComponent: React.FC<
  React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > &
    IArlindoProps
> = ({ type, ...props }) => (
  <ImageComponent
    src={getArlindoImageSrc(type)}
    alt="Arlindo"
    data-testid="Arlindo"
    {...props}
  />
)

ArlindoComponent.defaultProps = {
  type: 'default',
}
