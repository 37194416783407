import React from 'react'

import { ToastComponent } from '@/presentation/components/Feedback/Toast/ToastComponent'
import { IUseToastHookProps } from '@/presentation/hooks/UseToast/UseToast.types'
import { IToastConfig, NotifyTransitions, Toast } from '@codebox-team/web/utils'

export const toastDefaultConfigOptions: IToastConfig['options'] = {
  autoClose: 2000,
  hideProgressBar: true,
  pauseOnHover: false,
  pauseOnFocusLoss: false,
  icon: false,
  closeButton: false,
  position: 'bottom-right',
  transition: NotifyTransitions.Slide,
}

export const useToast = () => {
  return ({ message, title, content, options }: IUseToastHookProps) => {
    const screenWidth = window.screen.width

    Toast.success({
      content: content ?? <ToastComponent title={title} message={message} />,
      dismiss: false,
      options: {
        ...toastDefaultConfigOptions,
        ...(screenWidth >= 600 && { position: 'top-right' }),
        ...options,
      },
    })
  }
}
