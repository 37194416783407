import { InputTextComponent } from '@codebox-team/web/components'
import { StyleSheet } from '@codebox-team/web/utils'
import { COLORS } from '@customerportal/core'
import {
  InputLabelProps,
  OutlinedInputProps,
  InputProps,
  FilledInputProps,
} from '@mui/material'

export const StyledInputTextComponent = StyleSheet.create(InputTextComponent)<{
  InputProps:
    | Partial<OutlinedInputProps>
    | Partial<InputProps>
    | Partial<FilledInputProps>
  InputLabelProps: Partial<InputLabelProps>
}>`
  margin-bottom: 10px;

  label {
    ${({ InputLabelProps }) =>
      !InputLabelProps.shrink ? 'font-size: 14px' : 'unset'};

    left: ${({ InputProps, InputLabelProps }) =>
      !!InputProps?.startAdornment && !InputLabelProps?.shrink && '32px'};

    top: ${({ InputProps, InputLabelProps }) =>
      !!InputProps?.startAdornment && !InputLabelProps?.shrink && '2px'};
  }

  label[data-shrink='false'] {
    transform: translate(14px, 14px) scale(1);
  }

  input {
    padding: 4px 8px;
    height: 48px;
  }

  input {
    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      background: none;
      -webkit-box-shadow: 0 0 0px 1000px ${COLORS.paper} inset !important;
    }

    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  }
`
