import React from 'react'

import { translate } from '@/application/utils/Translate/TranslateUtil'
import FlagAr from '@/presentation/assets/icons/flag-ar.svg'
import FlagBr from '@/presentation/assets/icons/flag-br.svg'
import {
  ERegionCodes,
  ERegionIdentifiers,
  ERegionLocales,
  IRegion,
} from '@customerportal/core'

export const Regions: IRegion[] = [
  {
    code: ERegionCodes.AR,
    identifier: ERegionIdentifiers.AR,
    label: translate.t('countries.argentina'),
    locale: ERegionLocales.esES,
    phone: '54',
  },
  {
    code: ERegionCodes.BR,
    identifier: ERegionIdentifiers.BR,
    label: translate.t('countries.brazil'),
    locale: ERegionLocales.ptBR,
    phone: '55',
  },
]

export const RegionFlags: Record<ERegionIdentifiers, JSX.Element> = {
  [ERegionIdentifiers.BR]: <FlagBr />,
  [ERegionIdentifiers.AR]: <FlagAr />,
}
