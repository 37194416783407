import React from 'react'

import { IAppLayoutProviderProps } from '@/presentation/components/Providers/AppLayout/AppLayoutProvider.types'
import { AppLayoutPrivateProvider } from '@/presentation/components/Providers/AppLayout/Private/AppLayoutPrivateProvider'
import { AppLayoutPublicProvider } from '@/presentation/components/Providers/AppLayout/Public/AppLayoutPublicProvider'

export const AppLayoutProvider: React.FC<IAppLayoutProviderProps> = ({
  isPrivate,
  children,
}) =>
  isPrivate ? (
    <AppLayoutPrivateProvider testID="Private layout">
      {children}
    </AppLayoutPrivateProvider>
  ) : (
    <AppLayoutPublicProvider testID="Public layout">
      {children}
    </AppLayoutPublicProvider>
  )
