import React from 'react'

import { GlobalStyles } from '@codebox-team/web/components'

export const ThemeGlobalStyles: React.FC = () => (
  <GlobalStyles
    styles={_theme => ({
      ['*']: {
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        outline: 0,
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 'normal',
      },
      body: {
        width: '100%',
        height: '100%',
        fontSize: '16px',
        fontWeight: 300,
        background: '#FAFAFA',
      },
      input: {
        fontSize: '16px',
        fontWeight: 300,
        border: 'none',
      },
      button: {
        fontSize: '16px',
        fontWeight: 300,
        border: 'none',
      },

      ['div[role="dialog"] > div']: {
        padding: '8px 24px !important',
      },
    })}
  />
)
