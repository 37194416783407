import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { translate } from '@/application/utils/Translate/TranslateUtil'
import { ActivityIndicatorComponent } from '@/presentation/components/Feedback/ActivityIndicator/ActivityIndicatorComponent'
import { DialogComponent } from '@/presentation/components/Feedback/Dialog/DialogComponent'
import { AuthFormComponent } from '@/presentation/components/Forms/AuthForm/AuthFormComponent'
import {
  loginForm,
  useLoginFormRules,
} from '@/presentation/components/Forms/LoginForm/LoginFormComponent.rules'
import {
  StyledFormComponent,
  TermsAndPoliciesTextWrapper,
} from '@/presentation/components/Forms/LoginForm/LoginFormComponent.styles'
import {
  ILoginFormProps,
  TLoginForm,
} from '@/presentation/components/Forms/LoginForm/LoginFormComponent.types'
import { CheckboxComponent } from '@/presentation/components/Inputs/Checkbox/CheckboxComponent'
import { InputTextComponent } from '@/presentation/components/Inputs/InputText/InputTextComponent'
import { RegionAutocompleteComponent } from '@/presentation/components/Inputs/RegionAutocomplete/RegionAutocompleteComponent'
import { TermsAndPoliciesModal } from '@/presentation/modals/TermsAndPolicies/TermsAndPoliciesModal'
import { useForm } from '@codebox-team/react-hooks'
import {
  BoxComponent,
  ButtonComponent,
  DividerComponent,
  IconComponent,
} from '@codebox-team/web/components'
import { IRegion } from '@customerportal/core'

export const LoginFormComponent: React.FC<ILoginFormProps> = ({
  onSubmitLoginForm,
  onGetSupport,
  testID,
  ...props
}) => {
  const history = useHistory()

  const { isBusy, isFailure, isBadRequest, errorMessage } =
    useLoginFormRules(props)

  const [userHasComplied, setUserHasComplied] = useState<boolean>(false)
  const [isTermsAndPoliciesModalOpen, setIsTermsAndPoliciesModalOpen] =
    useState<boolean>(false)

  const openTermsAndPoliciesModal = () => setIsTermsAndPoliciesModalOpen(true)
  const closeTermsAndPoliciesModal = () => setIsTermsAndPoliciesModalOpen(false)

  const {
    register,
    changeRegisterRefName,
    handleSubmit,
    formState,
    setValue,
    watch,
  } = useForm<TLoginForm>(loginForm)

  const formValues = watch()

  const registerField = (name: keyof TLoginForm) =>
    changeRegisterRefName(register(name), 'inputRef')

  return (
    <div data-testid={testID}>
      <AuthFormComponent
        title={translate.t('components.login_form.title')}
        description={translate.t('components.login_form.description')}>
        <StyledFormComponent>
          <BoxComponent marginBottom="20px">
            <RegionAutocompleteComponent
              errorMessage={formState.errors.region?.message}
              onChangeRegion={(region: IRegion) => {
                setValue('region', region?.locale)
                setValue('country', region?.identifier)
                setValue('phonePrefix', region?.phone)
              }}
              testID="Region input field"
            />
          </BoxComponent>

          <InputTextComponent
            variant="outlined"
            label={translate.t('form.placeholders.type_email')}
            fullWidth
            error={!!formState.errors.login}
            helperText={formState.errors.login?.message}
            testID="Login input field"
            InputProps={{
              startAdornment: <IconComponent icon="person" />,
              onChange: ({ target: { value } }) => setValue('login', value),
            }}
            {...registerField('login')}
          />

          <DividerComponent classes={{ root: 'divider' }} />

          <BoxComponent
            className="wrapper__checkbox"
            display="flex"
            alignItems="center">
            <CheckboxComponent
              value={userHasComplied}
              disabled={!formValues.country}
              onChange={() => setUserHasComplied(!userHasComplied)}
              testID="Compliance checkbox"
            />
            <TermsAndPoliciesTextWrapper>
              <BoxComponent
                component="p"
                marginLeft={1}
                color="secondary.main"
                fontSize={14}
                fontWeight={400}
                lineHeight="100%">
                {translate.t('components.login_form.compliance.statement')}{' '}
                <BoxComponent
                  component="button"
                  color="primary.main"
                  fontWeight={700}
                  style={{ textDecorationLine: 'underline', cursor: 'pointer' }}
                  data-testid="Terms link"
                  onClick={() =>
                    formValues.country && openTermsAndPoliciesModal()
                  }
                  className={
                    formValues.country
                      ? 'openTermsAndPoliciesModalButton'
                      : 'openTermsAndPoliciesModalDisabledButton'
                  }>
                  {translate.t('components.login_form.compliance.terms')}
                </BoxComponent>{' '}
                {translate.t('general.and')}{' '}
                <BoxComponent
                  component="button"
                  color="primary.main"
                  fontWeight={700}
                  style={{ textDecorationLine: 'underline', cursor: 'pointer' }}
                  data-testid="Policies link"
                  onClick={() =>
                    formValues.country && openTermsAndPoliciesModal()
                  }
                  className={
                    formValues.country
                      ? 'openTermsAndPoliciesModalButton'
                      : 'openTermsAndPoliciesModalDisabledButton'
                  }>
                  {translate.t('components.login_form.compliance.policies')}
                </BoxComponent>
              </BoxComponent>
            </TermsAndPoliciesTextWrapper>
          </BoxComponent>

          <BoxComponent
            component="p"
            color="secondary.main"
            textAlign="center"
            fontSize="14px"
            fontWeight={400}
            lineHeight="120%"
            marginX={0}
            marginY="20px">
            {translate.t('general.cryptography')}
          </BoxComponent>

          <ButtonComponent
            fullWidth
            variant="contained"
            disabled={!userHasComplied}
            onClick={handleSubmit(onSubmitLoginForm)}
            testID="Submission button">
            {translate.t('general.next')}
          </ButtonComponent>
        </StyledFormComponent>
      </AuthFormComponent>

      <DialogComponent
        type="failure"
        isOpen={isFailure}
        title={translate.t('general.warning_title')}
        message={errorMessage}
        actions={[
          {
            type: 'primary',
            title: translate.t('general.try_again'),
            onClick: closeDialog => closeDialog(),
            testID: 'Try again action',
          },
          isBadRequest
            ? {
                type: 'secondary',
                title: translate.t('general.request_access'),
                onClick: () => history.push('/request-access'),
                testID: 'Request access action',
              }
            : {
                type: 'secondary',
                title: translate.t('general.talk_to_air_liquide'),
                onClick: onGetSupport,
                testID: 'Get support action',
              },
        ]}
        testID="Failure dialog"
      />

      {isBusy && (
        <ActivityIndicatorComponent
          messages={[
            translate.t('components.login_form.activity_indicator.message_1'),
            translate.t('components.login_form.activity_indicator.message_2'),
          ]}
          isOpen
          testID="Activity indicator dialog"
        />
      )}

      <TermsAndPoliciesModal
        country={formValues.country}
        isOpen={isTermsAndPoliciesModalOpen}
        onClose={closeTermsAndPoliciesModal}
        testID="Terms and policies modal"
      />
    </div>
  )
}
