import React from 'react'

import { translate } from '@/application/utils/Translate/TranslateUtil'
import { useTermsAndPoliciesScreenRules } from '@/presentation/components/Data/TermsAndPolicies/TermsAndPoliciesComponent.rules'
import {
  StyledTabsComponentWrapper,
  StyledContentWrapper,
} from '@/presentation/components/Data/TermsAndPolicies/TermsAndPoliciesComponent.styles'
import { ITermsAndPoliciesComponentProps } from '@/presentation/components/Data/TermsAndPolicies/TermsAndPoliciesComponent.types'
import {
  CircularProgressComponent,
  TabsComponent,
} from '@codebox-team/web/components'

export const TermsAndPoliciesComponent: React.FC<
  ITermsAndPoliciesComponentProps
> = props => {
  const { policies, terms, isPoliciesBusy, isTermsBusy } =
    useTermsAndPoliciesScreenRules(props)

  return (
    <StyledTabsComponentWrapper $isLoading={false}>
      <TabsComponent
        centered
        indicatorColor="primary"
        tabs={[
          {
            title: translate.t('screens.terms_and_policies.terms_title'),
            content: (
              <StyledContentWrapper>
                {isTermsBusy ? (
                  <CircularProgressComponent
                    data-testid="Terms And Policies loading"
                    color="primary"
                  />
                ) : (
                  <iframe
                    aria-controls="nodownload"
                    src={
                      !terms?.data?.url
                        ? ''
                        : `${terms.data.url}#zoom=100%&toolbar=0`
                    }
                    width="100%"
                    height="100%"
                  />
                )}
              </StyledContentWrapper>
            ),
          },
          {
            title: translate.t('screens.terms_and_policies.policies_title'),
            content: (
              <StyledContentWrapper>
                {isPoliciesBusy ? (
                  <CircularProgressComponent
                    data-testid="Terms And Policies loading"
                    color="primary"
                  />
                ) : (
                  <iframe
                    aria-controls="nodownload"
                    src={
                      !policies?.data?.url
                        ? ''
                        : `${policies.data.url}#zoom=100%&toolbar=0`
                    }
                    width="100%"
                    height="100%"
                  />
                )}
              </StyledContentWrapper>
            ),
          },
        ]}
      />
    </StyledTabsComponentWrapper>
  )
}
