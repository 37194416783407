import { StyleSheet } from '@codebox-team/web/utils'

export const StyledTabsComponentWrapper = StyleSheet.create('div')<{
  $isLoading: boolean
}>`
  display: flex;
  flex-direction: column;
  height: 50vh;

  > div {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  header {
    box-shadow: none;
    background: transparent;
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};

    > div {
      min-height: unset;
    }
  }

  div[role='tablist'] {
    button {
      text-transform: none;
      color: ${({ theme }) => theme.palette.primary.main};
      padding: 0px 12px 8px;
      line-height: 14px;
      min-height: unset;
    }

    button[aria-selected='true'] {
      > span {
        font-weight: ${({ theme }) => theme.typography.fontWeightBold};
      }
    }
  }

  div[role='tabpanel'] {
    flex-grow: 1;

    div {
      margin: 0 auto;
      width: 100%;
      height: 100%;
      padding: 0px;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: ${({ theme }) => theme.palette.primary.dark};
      }

      p {
        font-size: 14px;
        margin: 10px 0;
        color: ${({ theme }) => theme.palette.text.secondary};
      }
    }

    > div > div > div {
      ${({ $isLoading }) =>
        !$isLoading
          ? `
              padding: 32px 8px 0px 24px;
              height: 40vh;
              overflow-y: auto;

              *:last-child {
                padding-bottom: 32px;
                margin-bottom: 0;
              }

              ${({ theme }) => theme.breakpoints.down('sm')} {
                height: 50vh;
              }
            `
          : 'margin: calc(40vh / 2 - 40px);'}
    }
  }
`

export const StyledContentWrapper = StyleSheet.create('div')`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
