import React, { useMemo } from 'react'

import { useMoneyComponentStyles } from '@/presentation/components/Data/Money/MoneyComponent.styles'
import { IMoneyProps } from '@/presentation/components/Data/Money/MoneyComponent.types'
import { formatToLocaleCurrency } from '@codebox-team/tools'

export const MoneyComponent: React.FC<IMoneyProps> = ({
  value,
  language,
  testID,
}) => {
  const { classes } = useMoneyComponentStyles()

  const money = formatToLocaleCurrency({ value, language })

  const { left, right } = useMemo(() => {
    const start = 0
    const decimalPointPosition = money.length - 3
    const decimalNumberStart = money.length - 2
    const end = money.length

    return {
      left: money.substring(start, decimalPointPosition),
      right: money.substring(decimalNumberStart, end),
    }
  }, [money])

  return (
    <span className={classes.container} data-testid={testID}>
      <span className={classes.container}>{left}</span>{' '}
      <span className={`${classes.container} ${classes.decimal}`}>{right}</span>
    </span>
  )
}
