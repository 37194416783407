import React, { useEffect, useMemo, useState } from 'react'

import {
  StyledButtonComponent,
  StyledDialogComponent,
  StyledIconComponent,
} from '@/presentation/components/Feedback/Dialog/DialogComponent.styles'
import { IDialogProps } from '@/presentation/components/Feedback/Dialog/DialogComponent.types'
import { BoxComponent } from '@codebox-team/web/components'

export const DialogComponent: React.FC<IDialogProps> = ({
  type,
  title,
  message,
  actions,
  isOpen,
  testID,
  onClose,
  disableBackdropClick,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  useEffect(() => {
    setIsDialogOpen(isOpen)
  }, [isOpen])

  const onCloseHandler = () => {
    if (!disableBackdropClick) {
      onClose?.()
      setIsDialogOpen(false)
    }
  }

  const icon = useMemo(() => {
    const iconsByType: Record<
      IDialogProps['type'],
      { src: string; width: number; height: number }
    > = {
      success: { src: 'assets/img/worker-blinking.png', width: 43, height: 43 },
      failure: { src: 'assets/img/worker-sad.png', width: 43, height: 43 },
    }

    return iconsByType[type]
  }, [type])

  return (
    <StyledDialogComponent
      open={isDialogOpen}
      onClose={onCloseHandler}
      fullScreen={false}
      classes={{ paper: 'paper' }}
      testID={testID}>
      {!!icon && (
        <StyledIconComponent
          src={icon.src}
          alt="Icon"
          width={icon.width}
          height={icon.height}
          data-testid="Icon"
        />
      )}

      {!!title && (
        <BoxComponent
          component="p"
          color="primary.dark"
          textAlign="center"
          fontSize="body1.fontSize"
          fontWeight={700}
          lineHeight="120%"
          marginBottom={2}>
          {title}
        </BoxComponent>
      )}

      {!!message && (
        <BoxComponent
          component="p"
          color="primary.dark"
          textAlign="center"
          fontSize="body2.fontSize"
          fontWeight={400}
          lineHeight="120%"
          marginBottom="20px">
          {message}
        </BoxComponent>
      )}

      {actions?.map((action, index) => (
        <StyledButtonComponent
          key={index}
          onClick={() => {
            action.onClick(() => setIsDialogOpen(false))
          }}
          variant={action.type === 'primary' ? 'contained' : 'outlined'}
          fullWidth
          testID={action.testID ?? 'Action button'}
          index={index}>
          {action.title}
        </StyledButtonComponent>
      ))}
    </StyledDialogComponent>
  )
}
