import React from 'react'

import AirliquideLogo from '@/presentation/assets/img/logo.svg'
import { StylesAirLiquideLogoWrapper } from '@/presentation/components/Data/AirLiquideLogo/AirLiquideLogoComponent.styles'
import { IAirLiquideLogoProps } from '@/presentation/components/Data/AirLiquideLogo/AirLiquideLogoComponent.types'
import { ImageComponent } from '@/presentation/components/Data/Image/ImageComponent'

export const AirLiquideLogoComponent: React.FC<IAirLiquideLogoProps> = ({
  width,
  height,
  color,
  ...props
}) =>
  color === 'colorful' ? (
    <ImageComponent
      width={width}
      alt="Air Liquide Colorful Logo"
      height={height}
      src="assets/img/logo-colorful.png"
    />
  ) : (
    <StylesAirLiquideLogoWrapper
      title="Air Liquide logo"
      $width={width}
      $height={height}
      $color={color}
      {...props}>
      <AirliquideLogo />
    </StylesAirLiquideLogoWrapper>
  )

AirLiquideLogoComponent.defaultProps = {
  width: 107,
  height: 20,
  color: 'white',
}
