import {
  BoxComponent,
  GridComponent,
  TextComponent,
} from '@codebox-team/web/components'
import { StyleSheet } from '@codebox-team/web/utils'

export const StyledGridWrapperComponent = StyleSheet.create(GridComponent)`
padding: 32px 0 0 0;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-top: 1px solid ${({ theme }) => theme.palette.primary.dark};
`

export const StyledLogoGridComponent = StyleSheet.create(GridComponent)`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    text-align: center;
    justify-content: center;
  }
`

export const StyledTextComponent = StyleSheet.create(TextComponent)`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.secondary.main};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    text-align: center;
  }
`
export const StyledIconComponent = StyleSheet.create(BoxComponent)`
  cursor: pointer;
  margin: 0 8px;
  height: 24px;
`

export const StyledGridLinkWrapperComponent = StyleSheet.create(BoxComponent)`
  color: red;
  background-color: ${({ theme }) => theme.palette.secondary[100]};
  padding: 8px;
  margin-top: 32px;
`

export const StyledLinkTextComponent = StyleSheet.create(TextComponent)`
  font-size: 14px;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.dark};
`
