import { StyleSheet } from '@codebox-team/web/utils'

export const StylesAirLiquideLogoWrapper = StyleSheet.create('div')<{
  $width: number
  $height: number
  $color: string
}>`
  width: ${({ $width }) => $width}px;
  height: ${({ $height }) => $height}px;
  fill: ${({ theme, $color }) =>
    ({
      white: theme.palette.common.white,
      gray: theme.palette.secondary.main,
    }[$color])};

  svg {
    width: 100%;
    height: 100%;
    fill: inherit;

    path {
      fill: inherit;
    }
  }
`
