import { makeStyles } from '@codebox-team/web/components'

export const useMoneyComponentStyles = makeStyles()(() => ({
  container: {
    fontWeight: 'inherit',
  },
  decimal: {
    fontSize: '0.65em',
    lineHeight: 'normal',
    verticalAlign: 'text-top',
  },
}))
