import React from 'react'

import { BannerComponent } from '@/presentation/components/Surfaces/Banner/BannerComponent'
import { useSelector } from '@codebox-team/redux'
import { selectUserDetails } from '@customerportal/core'

export const BannerContainer: React.FC = () => {
  const userDetails = useSelector(selectUserDetails)

  return <BannerComponent name={userDetails?.name} />
}
