import { TextComponent } from '@codebox-team/web/components'
import { StyleSheet } from '@codebox-team/web/utils'

export const StyledBannerComponentWrapper = StyleSheet.create('div')`
  width: 100%;
  height: 190px;

  display: flex;
  justify-content: center;

  background-color: #b8d9ff;
  padding: 0 16px;

  overflow: hidden;
  position: relative;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 150px;
  }
`

export const StyledEllipseOut = StyleSheet.create('div')`
  width: 80.6944vw;
  height: 80.9027vw;

  border-radius: 100%;
  background-color: #2a7ede;

  padding-top: 12.6506%;
  padding-left: 11.3597%;

  position: absolute;
  margin-top: calc(-18.4027% - 48px);
  right: -5.4166%;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 113.0555vw;
    height: 113.3333vw;

    margin-top: calc(-18.0555% - 48px);
    right: -28.6111%;
  }
`

export const StyledEllipseIn = StyleSheet.create('div')`
  width: 56.6666vw;
  height: 56.7361vw;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.palette.primary.main};

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 79.1666vw;
    height: 79.7222vw;
  }
`

export const StyledBannerContentWrapper = StyleSheet.create('div')`
  width: 100%;
  height: 100%;
  max-width: 1000px;
  position: relative;

  display: flex;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding-top: 16px;
  }
`

export const StyledArlindoWrapper = StyleSheet.create('div')`
  height: 100%;
  flex: 1;
`

export const StyledInfoWrapper = StyleSheet.create('div')`
  height: 100%;
  flex: 1.5;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex: 2;
    flex-direction: column;
    align-items: flex-start;
  }
`

export const StyledTextWrapper = StyleSheet.create('div')`
  order: 1;
  max-width: 232px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    order: 2;
  }
`

export const StyledGreeting = StyleSheet.create(TextComponent)`
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 16px;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  line-height: 120%;
  margin-bottom: 20px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: 16px;
  }
`

export const StyledGreetingMessage = StyleSheet.create(TextComponent)`
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: 16px;
  }
`
