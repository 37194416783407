import React, { useEffect } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'

import { FirebaseConfig } from '@/application/config/Firebase/FirebaseConfig'
import { PushNotificationConfig } from '@/application/config/PushNotification/PushNotificationConfig'
import { persistor, store } from '@/application/store/StoreConfig'
import { translate } from '@/application/utils/Translate/TranslateUtil'
import { IAppProviderProps } from '@/presentation/components/Providers/App/AppProvider.types'
import { ThemeGlobalStyles } from '@/presentation/theme/global.styles'
import { themeOverrides } from '@/presentation/theme/variables'
import { AppStoreProvider } from '@codebox-team/redux'
import { Brands } from '@codebox-team/styleguide'
import { ThemeProvider } from '@codebox-team/web/components'
import { configureTheme } from '@codebox-team/web/utils'
import {
  EDevicePlatform,
  devicePushNotificationSubscribe,
} from '@customerportal/core'

const push = new PushNotificationConfig()

export const AppProvider: React.FC<IAppProviderProps> = ({ children }) => {
  const theme = configureTheme({
    type: 'light',
    brandType: Brands.AirLiquide,
    overrides: themeOverrides,
  })

  const handleInitPushNotifications = async () => {
    const token = await push.register(FirebaseConfig.app)

    store.dispatch(
      devicePushNotificationSubscribe({
        token,
        platform: EDevicePlatform.web,
      }),
    )
  }

  useEffect(() => {
    handleInitPushNotifications()

    document.documentElement.lang = translate.language
  }, [])

  return (
    <AppStoreProvider store={store} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <BrowserRouter basename={process.env.VIRTUAL_PATH || '/'}>
          <Switch>{children}</Switch>
        </BrowserRouter>
        <ThemeGlobalStyles />
      </ThemeProvider>
    </AppStoreProvider>
  )
}
