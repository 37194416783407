import React from 'react'

import { BreadcrumbsComponent } from '@/presentation/components/Navigation/Breadcrumbs/BreadcrumbsComponent'
import { MainMenuComponent } from '@/presentation/components/Navigation/MainMenu/MainMenuComponent'
import { useAppLayoutPrivateProviderRules } from '@/presentation/components/Providers/AppLayout/Private/AppLayoutPrivateProvider.rules'
import {
  StyledPrivateContentWrapper,
  StyledAppLayoutPrivateProviderWrapper,
  StyledPrivateMainContentWrapper,
} from '@/presentation/components/Providers/AppLayout/Private/AppLayoutPrivateProvider.styles'
import { IAppLayoutPrivateProviderProps } from '@/presentation/components/Providers/AppLayout/Private/AppLayoutPrivateProvider.types'
import { FooterComponent } from '@/presentation/components/Surfaces/Footer/FooterComponent'
import { BannerContainer } from '@/presentation/containers/Banner/BannerContainer'
import { FeedbackToastContainer } from '@/presentation/containers/FeedbackToast/FeedbackToastContainer'
import { HeaderContainer } from '@/presentation/containers/Header/HeaderContainer'
import { BoxComponent, DividerComponent } from '@codebox-team/web/components'

export const AppLayoutPrivateProvider: React.FC<
  IAppLayoutPrivateProviderProps
> = ({ testID, children }) => {
  const { menuItems, userIsCustomer } = useAppLayoutPrivateProviderRules()

  return (
    <StyledAppLayoutPrivateProviderWrapper data-testid={testID}>
      <HeaderContainer />
      {userIsCustomer ? <BannerContainer /> : <BoxComponent paddingTop={2} />}
      <FeedbackToastContainer />
      <StyledPrivateContentWrapper>
        <StyledPrivateMainContentWrapper>
          <BreadcrumbsComponent />
          {menuItems?.length > 0 && (
            <>
              <MainMenuComponent
                className="menu"
                testID="Main menu"
                menus={menuItems}
              />
              <DividerComponent classes={{ root: 'divider' }} />
            </>
          )}
          {children}
        </StyledPrivateMainContentWrapper>
      </StyledPrivateContentWrapper>
      <FooterComponent />
    </StyledAppLayoutPrivateProviderWrapper>
  )
}
