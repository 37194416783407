import React from 'react'

import { translate } from '@/application/utils/Translate/TranslateUtil'
import { MoneyComponent } from '@/presentation/components/Data/Money/MoneyComponent'
import { useTotalValueComponentRules } from '@/presentation/components/Data/TotalValue/TotalValueComponent.rules'
import { useTotalValueComponentStyles } from '@/presentation/components/Data/TotalValue/TotalValueComponent.styles'
import { ITotalValueComponentProps } from '@/presentation/components/Data/TotalValue/TotalValueComponent.types'
import { BoxComponent } from '@codebox-team/web/components'

export const TotalValueComponent: React.FC<ITotalValueComponentProps> = ({
  testID,
  value,
}) => {
  const { dotCount, fillerRef } = useTotalValueComponentRules()
  const { classes } = useTotalValueComponentStyles()

  return (
    <BoxComponent className={classes.container} testID={testID}>
      {translate.t('general.total')}

      <span className={classes.filler} ref={fillerRef}>
        {'.'.repeat(dotCount)}
      </span>

      <MoneyComponent language="pt-BR" value={value} />
    </BoxComponent>
  )
}
