import { useEffect } from 'react'

import FacebookIcon from '@/presentation/assets/icons/facebook-icon.svg'
import InstagramIcon from '@/presentation/assets/icons/instagram-icon.svg'
import LinkedInIcon from '@/presentation/assets/icons/linked-in-icon.svg'
import YoutubeIcon from '@/presentation/assets/icons/youtube-icon.svg'
import {
  EContactSocialTitle,
  useContactEnterpriseLoad,
  useContactLinkLoad,
  useContactSocialLoad,
} from '@customerportal/core'

export const iconContactSocial: Record<EContactSocialTitle, string> = {
  facebook: FacebookIcon,
  instagram: InstagramIcon,
  linkedin: LinkedInIcon,
  youtube: YoutubeIcon,
}

export const useFooterComponentRules = () => {
  const contactLinkLoad = useContactLinkLoad()
  const contactSocialLoad = useContactSocialLoad()
  const contactEnterpriseLoad = useContactEnterpriseLoad()

  useEffect(() => {
    contactLinkLoad.handleFetch({})
    contactEnterpriseLoad.handleFetch({})
    contactSocialLoad.handleFetch({})

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { contactLinkLoad, contactSocialLoad, contactEnterpriseLoad }
}
