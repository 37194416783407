import React from 'react'

import { translate } from '@/application/utils/Translate/TranslateUtil'
import { urlFormatUtil } from '@/application/utils/Url/UrlUtil'
import LinkIcon from '@/presentation/assets/icons/link-icon.svg'
import { AirLiquideLogoComponent } from '@/presentation/components/Data/AirLiquideLogo/AirLiquideLogoComponent'
import {
  iconContactSocial,
  useFooterComponentRules,
} from '@/presentation/components/Surfaces/Footer//FooterComponent.rules'
import {
  FooterComponentEnterpriseSkeleton,
  FooterComponentLinkSkeleton,
  FooterComponentSocialMediaSkeleton,
} from '@/presentation/components/Surfaces/Footer/FooterComponent.skeleton'
import {
  StyledGridLinkWrapperComponent,
  StyledGridWrapperComponent,
  StyledIconComponent,
  StyledLinkTextComponent,
  StyledLogoGridComponent,
  StyledTextComponent,
} from '@/presentation/components/Surfaces/Footer/FooterComponent.styles'
import { themeOverrides } from '@/presentation/theme/variables'
import {
  GridComponent,
  BoxComponent,
  TextComponent,
} from '@codebox-team/web/components'

export const FooterComponent: React.FC = () => {
  const { contactSocialLoad, contactEnterpriseLoad, contactLinkLoad } =
    useFooterComponentRules()

  const contactLinkLoadData = contactLinkLoad.data
  const contactEnterpriseLoadData = contactEnterpriseLoad.data
  const contactSocialLoadData = contactSocialLoad.data

  return (
    <StyledGridWrapperComponent container justifyContent="center">
      <GridComponent item xs={12} md={9}>
        <GridComponent container spacing={2}>
          <StyledLogoGridComponent item xs={12} sm={6} md={4}>
            <AirLiquideLogoComponent
              width={183}
              height={35}
              color="colorful"
              data-testid="Footer Logo"
            />
          </StyledLogoGridComponent>
          <StyledLogoGridComponent item xs={12} md={4} />
          <StyledLogoGridComponent item xs={12} sm={6} md={4}>
            <BoxComponent
              display="flex"
              flexDirection="row"
              padding={0}
              alignItems="center">
              {contactSocialLoad.isWaiting && (
                <FooterComponentSocialMediaSkeleton />
              )}
              {!contactSocialLoad.isWaiting &&
                contactSocialLoadData?.length > 0 && (
                  <StyledLogoGridComponent display="flex" width="100%">
                    <TextComponent
                      fontSize={14}
                      color={themeOverrides.palette.primary.main}>
                      {translate.t('components.footer.follow_us')}
                    </TextComponent>
                    {contactSocialLoadData.map(socialMedia => {
                      const SvgIcon =
                        iconContactSocial[socialMedia.title] ?? LinkIcon

                      return (
                        <StyledIconComponent
                          key={socialMedia.key}
                          testID="Footer Social Media"
                          onClick={() =>
                            window.open(
                              urlFormatUtil(socialMedia.value),
                              '_blank',
                              'noreferrer',
                            )
                          }>
                          <SvgIcon />
                        </StyledIconComponent>
                      )
                    })}
                  </StyledLogoGridComponent>
                )}
            </BoxComponent>
          </StyledLogoGridComponent>

          {contactEnterpriseLoad.isWaiting &&
            [...new Array(3).keys()].map(key => (
              <FooterComponentEnterpriseSkeleton key={key} />
            ))}

          {!contactEnterpriseLoad.isWaiting && contactEnterpriseLoadData && (
            <>
              <GridComponent xs={12} item sm={6} md={4}>
                <StyledTextComponent data-testid="Footer Text">
                  {contactEnterpriseLoadData.name}
                </StyledTextComponent>
                <StyledTextComponent data-testid="Footer Text">
                  {contactEnterpriseLoadData.document}
                </StyledTextComponent>
              </GridComponent>
              <GridComponent item xs={12} sm={6} md={4}>
                <StyledTextComponent data-testid="Footer Text">
                  {contactEnterpriseLoadData.address}
                </StyledTextComponent>
                <StyledTextComponent data-testid="Footer Text">
                  {`${contactEnterpriseLoadData.zipcode} • ${contactEnterpriseLoadData.city}`}
                </StyledTextComponent>
              </GridComponent>
              <GridComponent item xs={12} sm={6} md={4}>
                <StyledTextComponent data-testid="Footer Text">
                  {`${translate.t('general.corporate_phone')} ${
                    contactEnterpriseLoadData.phone
                  }`}
                </StyledTextComponent>
                <StyledTextComponent data-testid="Footer Text">
                  {`${translate.t('components.footer.working_hours')} ${
                    contactEnterpriseLoadData.workingHours
                  }`}
                </StyledTextComponent>
              </GridComponent>
            </>
          )}
        </GridComponent>
      </GridComponent>
      <StyledGridLinkWrapperComponent
        display="flex"
        justifyContent="space-around"
        width="100%">
        {contactLinkLoad.isWaiting ? (
          <FooterComponentLinkSkeleton />
        ) : (
          contactLinkLoadData?.map(link => (
            <StyledLinkTextComponent
              onClick={() =>
                window.open(urlFormatUtil(link.value), '_blank', 'noreferrer')
              }
              key={link.key}
              data-testid="Footer Link Text">
              {link.title}
            </StyledLinkTextComponent>
          ))
        )}
      </StyledGridLinkWrapperComponent>
    </StyledGridWrapperComponent>
  )
}
