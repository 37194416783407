import React from 'react'

import { FeedbackContainer } from '@/presentation/containers/Feedback/FeedbackContainer'
import { useFeedbackToastContainerRules } from '@/presentation/containers/FeedbackToast/FeedbackToastContainer.rules'
import { StyledFeedbackDialog } from '@/presentation/containers/FeedbackToast/FeedbackToastContainer.styles'
import { BoxComponent } from '@codebox-team/web/components'

export const FeedbackToastContainer: React.FC = () => {
  const { isOpen, onClose } = useFeedbackToastContainerRules()

  return (
    <StyledFeedbackDialog
      classes={{ paper: 'paper' }}
      fullWidth={false}
      onClose={onClose}
      open={isOpen}
      testID="Feedback dialog">
      <BoxComponent py={2}>
        <FeedbackContainer action={onClose} variant="dialog" />
      </BoxComponent>
    </StyledFeedbackDialog>
  )
}
