import { TextComponent } from '@codebox-team/web/components'
import { StyleSheet } from '@codebox-team/web/utils'

export const StyledTopContent = StyleSheet.create('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`

export const StyledTitle = StyleSheet.create(TextComponent)`
  color: ${({ theme }) => theme.palette.primary.dark};
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  margin-right: 8px;
`

export const StyledMessage = StyleSheet.create(TextComponent)`
  color: ${({ theme }) => theme.palette.secondary.main};
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
`
