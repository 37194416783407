import React from 'react'

import { useBreadcrumbsByPathName } from '@/presentation/components/Navigation/Breadcrumbs/BreadcrumbsComponent.rules'
import { StyledBreadcrumbsComponentWrapper } from '@/presentation/components/Navigation/Breadcrumbs/BreadcrumbsComponent.styles'
import { BreadcrumbComponent } from '@codebox-team/web/components'

export const BreadcrumbsComponent: React.FC = () => {
  const breadcrumbs = useBreadcrumbsByPathName()

  return (
    <StyledBreadcrumbsComponentWrapper data-testid="Breadcrumbs">
      <BreadcrumbComponent paths={breadcrumbs} />
    </StyledBreadcrumbsComponentWrapper>
  )
}
