import { StyleSheet } from '@codebox-team/web/utils'

export const StyledFormComponent = StyleSheet.create('div')`
  .divider {
    background-color: ${({ theme }) => theme.palette.divider};
    margin: 20px 0;
  }

  .wrapper__checkbox {
    span {
      padding: 0;
    }
  }
`

export const TermsAndPoliciesTextWrapper = StyleSheet.create('div')`
  .openTermsAndPoliciesModalButton {
    background: transparent;
    font-size: 14px;
  }

  .openTermsAndPoliciesModalDisabledButton {
    background: transparent;
    font-size: 14px;
    opacity: 0.2;
    cursor: default;
  }

  .openTermsAndPoliciesModalButton:focus {
    opacity: 0.7;
  }
`
