import { MenuComponent } from '@codebox-team/web/components'
import { StyleSheet } from '@codebox-team/web/utils'

export const StyledMenuWrapper = StyleSheet.create('div')`
  display: flex;
  align-items: center;
  flex-grow: 1;
`

export const StyledCartSummaryWrapper = StyleSheet.create(MenuComponent)`
  .cart__summary__wrapper {
    top: 72px !important;
    box-shadow: none;
    min-width: 312px;
    background-color: ${({ theme }) => theme.palette.common.white};

    ul {
      padding-bottom: 0;
    }
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    .cart__summary__wrapper {
      width: 100%;
      max-width: 100%;
      left: 0 !important;
      border-radius: 0;
    }
  }
`

export const StyledCartSummaryButtonWrapper = StyleSheet.create('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 312px;
  padding: 16px;
  background-color: ${({ theme }) => theme.palette.secondary['100']};
  border-top: ${({ theme }) => `1px solid ${theme.palette.primary['main']}`};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    max-width: 100%;
  }
`

export const StyledCartSummaryIconWrapper = StyleSheet.create('div')`
  display: flex;

  > img {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
  }
`
