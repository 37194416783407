import { IUserDetails } from '@customerportal/core'

export interface IHeaderMenuProps {
  handleLogout: () => void
  userDetails: IUserDetails
  isLoading?: boolean
}

export interface IHeaderMenuItem {
  text: string
  link: string
  svgIcon: string
}

export enum EHeaderMenuConfig {
  cpfLength = 11,
  skeletons = 4,
}
