import React from 'react'

import {
  StyledCheckboxCheckedIcon,
  StyledCheckboxCheckedIconInside,
  StyledCheckboxIcon,
} from '@/presentation/components/Inputs/Checkbox/CheckboxComponent.styles'
import { ICheckboxProps } from '@/presentation/components/Inputs/Checkbox/CheckboxComponent.types'
import { CheckboxComponent as CheckboxComponentLib } from '@codebox-team/web/components'

export const CheckboxComponent: React.FC<ICheckboxProps> = ({
  value,
  disabled,
  onChange,
  testID,
}) => (
  <CheckboxComponentLib
    onChange={onChange}
    testID={testID}
    disabled={disabled}
    value={value}
    icon={<StyledCheckboxIcon />}
    checkedIcon={
      <StyledCheckboxCheckedIcon>
        <StyledCheckboxCheckedIconInside
          src="assets/img/check.svg"
          alt="Check"
        />
      </StyledCheckboxCheckedIcon>
    }
  />
)
