import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { batch, useDispatch, useSelector } from '@codebox-team/redux'
import {
  EActionStatus,
  IUserCustomer,
  cartLoad,
  deliveryPointsLoad,
  productsLoad,
  selectCartData,
  selectDeliveryPointsCurrentDeliveryPointCode,
  selectUserActionStatus,
  selectUserCustomers,
  selectUserCustomersActionStatus,
  selectUserDetails,
  selectUserPreferencesActionStatus,
  selectUserRolesActionStatus,
  useIsLoading,
  userActionStatusClear,
  userClear,
  userCustomersRequest,
  userDetailsRequest,
  userPreferencesRequest,
  userRolesRequest,
} from '@customerportal/core'

export const useUserCustomers = () => {
  const dispatch = useDispatch()

  const history = useHistory()

  const data = useSelector(selectUserCustomers)
  const dataStatus = useSelector(selectUserCustomersActionStatus)
  const preferencesStatus = useSelector(selectUserPreferencesActionStatus)
  const userDetails = useSelector(selectUserDetails)
  const userStatus = useSelector(selectUserActionStatus)
  const userDeliveryAddressCode = useSelector(
    selectDeliveryPointsCurrentDeliveryPointCode,
  )

  const [currentCustomer, setCurrentCustomer] = useState<IUserCustomer | null>(
    null,
  )

  const handleFetch = useCallback(() => {
    dispatch(userCustomersRequest())
  }, [dispatch])

  const { isLoading } = useIsLoading({
    statusList: [dataStatus, preferencesStatus, userStatus],
  })

  const getCustomerDetails = (customerId: string) =>
    data?.find(customer => customer.customerId === customerId)

  const onSelect = (customerId?: string) => {
    if (customerId) {
      setCurrentCustomer(getCustomerDetails(customerId))
    }

    dispatch(
      userPreferencesRequest({
        customerId: customerId || currentCustomer?.customerId,
      }),
    )

    dispatch(deliveryPointsLoad({ customerId }))
  }

  useEffect(() => {
    if (userDetails) {
      setCurrentCustomer({
        customerId: userDetails?.customerId,
        document: userDetails?.document,
        legalName: userDetails?.identifier,
      })
    }
  }, [userDetails])

  useEffect(() => {
    handleFetch()
  }, [handleFetch])

  useEffect(() => {
    if (preferencesStatus?.type === EActionStatus.Success) {
      dispatch(userDetailsRequest())
    }
  }, [dispatch, preferencesStatus?.type])

  useEffect(() => {
    if (userStatus?.type === EActionStatus.Success) {
      dispatch(userActionStatusClear())
      history.push('/')
    }
  }, [dispatch, history, userStatus?.type])

  useEffect(() => {
    if (userDeliveryAddressCode) {
      batch(() => {
        dispatch(
          productsLoad({
            customerId: currentCustomer?.customerId,
            deliveryAddressCode: userDeliveryAddressCode,
          }),
        )
        dispatch(
          cartLoad({
            customerId: currentCustomer?.customerId,
            deliveryAddressCode: userDeliveryAddressCode,
          }),
        )
      })
    }
  }, [currentCustomer?.customerId, dispatch, userDeliveryAddressCode])

  return {
    currentCustomer,
    data,
    isLoading,
    onSelect,
  }
}

export const useHeaderContainerRules = () => {
  const dispatch = useDispatch()

  const userDetails = useSelector(selectUserDetails)
  const userActionStatus = useSelector(selectUserActionStatus)
  const userRolesActionStatus = useSelector(selectUserRolesActionStatus)
  const cartProducts = useSelector(selectCartData)

  const { isLoading } = useIsLoading({
    statusList: [userActionStatus, userRolesActionStatus],
  })

  const handleLogout = () => dispatch(userClear())

  useEffect(() => {
    batch(() => {
      dispatch(userDetailsRequest())
      dispatch(userRolesRequest())
    })
  }, [dispatch])

  return {
    cartProducts,
    handleLogout,
    isLoading,
    userDetails,
  }
}
