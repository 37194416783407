import React from 'react'

import { StyledHeaderComponentLibWrapperComponent } from '@/presentation/components/Surfaces/Header/HeaderComponent.styles'
import { IHeaderComponentProps } from '@/presentation/components/Surfaces/Header/HeaderComponent.types'
import { HeaderComponent as HeaderComponentLib } from '@codebox-team/web/components'

export const HeaderComponent: React.FC<IHeaderComponentProps> = ({
  children,
}) => (
  <StyledHeaderComponentLibWrapperComponent>
    <HeaderComponentLib classes={{ root: 'header' }}>
      {children}
    </HeaderComponentLib>
  </StyledHeaderComponentLibWrapperComponent>
)
