import React from 'react'

import { translate } from '@/application/utils/Translate/TranslateUtil'
import { TermsAndPoliciesComponent } from '@/presentation/components/Data/TermsAndPolicies/TermsAndPoliciesComponent'
import {
  StyledCloseButton,
  StyledContentWrapper,
  StyledDialogComponent,
  StyledDialogFooter,
  StyledTitle,
} from '@/presentation/modals/TermsAndPolicies/TermsAndPoliciesModal.styles'
import { ITermsAndPoliciesModalProps } from '@/presentation/modals/TermsAndPolicies/TermsAndPoliciesModal.types'
import { ButtonComponent } from '@codebox-team/web/components'

export const TermsAndPoliciesModal: React.FC<ITermsAndPoliciesModalProps> = ({
  country,
  isOpen,
  onClose,
  testID,
}) => (
  <StyledDialogComponent
    open={isOpen}
    fullScreen={false}
    maxWidth={false}
    classes={{
      paper: 'dialog_paper',
    }}
    testID={testID}>
    <StyledContentWrapper>
      <StyledCloseButton
        src="assets/img/ic_close.png"
        alt="Close icon"
        role="button"
        onClick={onClose}
        data-testid="Close button"
      />

      <StyledTitle
        component="p"
        dangerouslySetInnerHTML={{
          __html: translate.t('screens.terms_and_policies.title'),
        }}
      />

      <TermsAndPoliciesComponent country={country} />

      <StyledDialogFooter>
        <ButtonComponent
          variant="contained"
          onClick={onClose}
          className="button">
          {translate.t('screens.terms_and_policies.go_back_button')}
        </ButtonComponent>
      </StyledDialogFooter>
    </StyledContentWrapper>
  </StyledDialogComponent>
)
