import React, { useState } from 'react'

import { translate } from '@/application/utils/Translate/TranslateUtil'
import DomainIcon from '@/presentation/assets/icons/domain-icon.svg'
import {
  StyledMenuItemComponent,
  StyledSelectComponent,
} from '@/presentation/components/Inputs/SelectCustomer/SelectCustomerComponent.styles'
import { ISelectCustomerComponentProps } from '@/presentation/components/Inputs/SelectCustomer/SelectCustomerComponent.types'
import { themeOverrides } from '@/presentation/theme/variables'
import { BoxComponent } from '@codebox-team/web/components'

export const SelectCustomerComponent: React.FC<
  ISelectCustomerComponentProps
> = ({ currentCustomer, data, isLoading, onSelect }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const getValue = () => {
    if (isLoading) {
      return translate.t('components.select_customer.loading')
    }

    if (isOpen) {
      return translate.t('components.select_customer.select')
    }

    return `${currentCustomer?.legalName} • ${currentCustomer?.document} • ${currentCustomer?.customerId}`
  }

  return (
    <StyledSelectComponent
      disabled={isLoading || data?.length === 0}
      fullWidth
      onChange={({ target: { value } }) => onSelect(value as string)}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      open={isOpen}
      renderValue={() => (
        <BoxComponent
          alignItems="center"
          display="flex"
          gap="8px"
          role="combobox">
          <DomainIcon />
          <span data-testid="Current customer">{getValue()}</span>
        </BoxComponent>
      )}
      testID="Select customer"
      variant="outlined"
      value={currentCustomer?.customerId ?? ''}>
      {data?.map(({ customerId, document, legalName }) => (
        <StyledMenuItemComponent
          aria-label="Customer option"
          key={customerId}
          value={customerId}>
          <BoxComponent
            color={themeOverrides.palette.primary.main}
            fontSize={14}
            testID="Customer option top">
            {legalName}
          </BoxComponent>
          <BoxComponent
            color={themeOverrides.palette.secondary.main}
            fontSize={14}
            testID="Customer option bottom">
            {`${document} • ${customerId}`}
          </BoxComponent>
        </StyledMenuItemComponent>
      ))}
    </StyledSelectComponent>
  )
}
