import { DialogComponent, TextComponent } from '@codebox-team/web/components'
import { StyleSheet } from '@codebox-team/web/utils'

export const StyledTitle = StyleSheet.create(TextComponent)`
  color: ${({ theme }) => theme.palette.primary.dark};
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  margin-left: 8px;
`

export const StyledMessage = StyleSheet.create(TextComponent)`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  margin-top: 16px;
`

export const StyledFeedbackDialog = StyleSheet.create(DialogComponent)`
  top: unset;
  left: unset;
  transform: unset;
  right: 0px;
  bottom: 0px;

  .paper {
    div {
      max-width: 380px;
    }
  }
`
