import React from 'react'
import { createRoot } from 'react-dom/client'

import { App } from '@/application/App'
import { FirebaseConfig } from '@/application/config/Firebase/FirebaseConfig'
import { envDecryptedCore } from '@customerportal/core'

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

if (envDecryptedCore.ENV_TYPE === 'production') {
  FirebaseConfig.analytics()
}
