import { lazy, useEffect } from 'react'

import { usePagePermission } from '@/presentation/hooks/UsePagePermission/UsePagePermission'
import { LoginScreen } from '@/presentation/screens/Login/LoginScreen'
import { useSelector } from '@codebox-team/redux'
import { IRoute } from '@codebox-team/typings/menu-navigation'
import {
  EUserRoles,
  HttpClientAuthorizedConfigRules,
  selectUserCountry,
  selectUserDetails,
  selectUserIsAuthenticated,
} from '@customerportal/core'

export const useRoutesRules = () => {
  const userDetails = useSelector(selectUserDetails)
  const userCountry = useSelector(selectUserCountry)
  const isUserAuthenticated = useSelector(selectUserIsAuthenticated)

  const userHasStockAccess = usePagePermission(EUserRoles.PortalStock)
  const userHasManagerRegistrationAccess = usePagePermission(
    EUserRoles.PortalManagerRegistration,
  )
  const userHasBusinessGroupsAccess = usePagePermission(
    EUserRoles.PortalBusinessGroups,
  )
  const userHasUserAccessAccess = usePagePermission(EUserRoles.PortalUserAccess)
  const userHasFinancialAccess = usePagePermission(EUserRoles.PortalFinancial)
  const userHasOrdersCreationAccess = usePagePermission(
    EUserRoles.PortalOrdersCreation,
  )
  const userHasOrdersTrackingAccess = usePagePermission(
    EUserRoles.PortalOrdersTracking,
  )
  const userHasRegisteredContactsAccess = usePagePermission(
    EUserRoles.PortalRegisteredContacts,
  )
  const userHasParametersAccess = usePagePermission(EUserRoles.PortalParameters)
  const userHasTermsAndPoliciesAccess = usePagePermission(
    EUserRoles.PortalTermsAndPolicies,
  )
  const userHasMarketingAccess = usePagePermission(EUserRoles.PortalMarketing)

  const isAdmin = userDetails?.isAdmin

  const adminHomePath: string =
    isAdmin && userHasManagerRegistrationAccess
      ? '/manager-registration'
      : '/business-groups'

  const appRoutes: IRoute[] = [
    ...(userHasOrdersCreationAccess
      ? [
          {
            path: '/',
            component: lazy(() =>
              import('@/presentation/screens/Home/HomeScreen').then(
                ({ HomeScreen }) => ({
                  default: HomeScreen,
                }),
              ),
            ),
            exact: true,
            isPrivate: true,
          },
        ]
      : []),
    ...(!isAdmin && !userHasOrdersCreationAccess
      ? [
          {
            path: '/',
            component: lazy(() =>
              import('@/presentation/screens/Contact/ContactScreen').then(
                ({ ContactScreen }) => ({
                  default: ContactScreen,
                }),
              ),
            ),
            exact: true,
            isPrivate: true,
          },
        ]
      : []),
    {
      path: '/contact',
      component: lazy(() =>
        import('@/presentation/screens/Contact/ContactScreen').then(
          ({ ContactScreen }) => ({
            default: ContactScreen,
          }),
        ),
      ),
      exact: true,
      isPrivate: true,
    },
    {
      path: '/terms-and-policies',
      component: lazy(() =>
        import(
          '@/presentation/screens/TermsAndPolicies/TermsAndPoliciesScreen'
        ).then(({ TermsAndPoliciesScreen }) => ({
          default: TermsAndPoliciesScreen,
        })),
      ),
      exact: true,
      isPrivate: true,
    },
    {
      path: '/addresses',
      component: lazy(() =>
        import('@/presentation/screens/Addresses/AddressesScreen').then(
          ({ AddressesScreen }) => ({
            default: AddressesScreen,
          }),
        ),
      ),
      exact: true,
      isPrivate: true,
    },
    ...(userHasOrdersTrackingAccess
      ? [
          {
            path: '/orders',
            component: lazy(() =>
              import('@/presentation/screens/Orders/OrdersScreen').then(
                ({ OrdersScreen }) => ({
                  default: OrdersScreen,
                }),
              ),
            ),
            exact: true,
            isPrivate: true,
          },
          {
            path: '/orders/:orderId',
            component: lazy(() =>
              import(
                '@/presentation/screens/OrderDetails/OrderDetailsScreen'
              ).then(({ OrderDetailsScreen }) => ({
                default: OrderDetailsScreen,
              })),
            ),
            exact: true,
            isPrivate: true,
          },
        ]
      : []),
    ...(userHasFinancialAccess
      ? [
          {
            path: '/financial',
            component: lazy(() =>
              import('@/presentation/screens/Financial/FinancialScreen').then(
                ({ FinancialScreen }) => ({
                  default: FinancialScreen,
                }),
              ),
            ),
            exact: true,
            isPrivate: true,
          },
          {
            path: '/financial/:invoiceNumber',
            component: lazy(() =>
              import('@/presentation/screens/BankSlips/BankSlipsScreen').then(
                ({ BankSlipsScreen }) => ({
                  default: BankSlipsScreen,
                }),
              ),
            ),
            exact: true,
            isPrivate: true,
          },
        ]
      : []),
    {
      path: '/cart',
      component: lazy(() =>
        import('@/presentation/screens/Cart/CartScreen').then(
          ({ CartScreen }) => ({
            default: CartScreen,
          }),
        ),
      ),
      exact: true,
      isPrivate: true,
    },
    {
      path: '/feedback',
      component: lazy(() =>
        import('@/presentation/screens/Feedback/FeedbackScreen').then(
          ({ FeedbackScreen }) => ({
            default: FeedbackScreen,
          }),
        ),
      ),
      exact: true,
      isPrivate: true,
    },
    ...(userHasStockAccess
      ? [
          {
            path: '/stock',
            component: lazy(() =>
              import('@/presentation/screens/Stock/StockScreen').then(
                ({ StockScreen }) => ({ default: StockScreen }),
              ),
            ),
            exact: true,
            isPrivate: true,
          },
        ]
      : []),
    ...(userHasManagerRegistrationAccess
      ? [
          {
            path: '/manager-registration',
            component: lazy(() =>
              import(
                '@/presentation/screens/ProfileManager/ManagerRegistration/ManagerRegistrationScreen'
              ).then(({ ManagerRegistrationScreen }) => ({
                default: ManagerRegistrationScreen,
              })),
            ),
            exact: true,
            isPrivate: true,
          },
        ]
      : []),
    ...(userHasBusinessGroupsAccess
      ? [
          {
            path: '/business-groups',
            component: lazy(() =>
              import(
                '@/presentation/screens/ProfileManager/BusinessGroups/BusinessGroupsScreen'
              ).then(({ BusinessGroupsScreen }) => ({
                default: BusinessGroupsScreen,
              })),
            ),
            exact: true,
            isPrivate: true,
          },
          {
            path: '/business-groups-registration',
            component: lazy(() =>
              import(
                '@/presentation/screens/ProfileManager/BusinessGroupsRegistration/BusinessGroupsRegistrationScreen'
              ).then(({ BusinessGroupsRegistrationScreen }) => ({
                default: BusinessGroupsRegistrationScreen,
              })),
            ),
            exact: true,
            isPrivate: true,
          },
          {
            path: '/business-group-detail/:key',
            component: lazy(() =>
              import(
                '@/presentation/screens/ProfileManager/BusinessGroupDetail/BusinessGroupDetailScreen'
              ).then(({ BusinessGroupDetailScreen }) => ({
                default: BusinessGroupDetailScreen,
              })),
            ),
            exact: true,
            isPrivate: true,
          },
        ]
      : []),
    ...(userHasParametersAccess
      ? [
          {
            path: '/params',
            component: lazy(() =>
              import('@/presentation/screens/Params/ParamsScreen').then(
                ({ ParamsScreen }) => ({
                  default: ParamsScreen,
                }),
              ),
            ),
            exact: true,
            isPrivate: true,
          },
        ]
      : []),
    ...(userHasTermsAndPoliciesAccess
      ? [
          {
            path: '/terms-and-policies-manager',
            component: lazy(() =>
              import(
                '@/presentation/screens/TermsAndPoliciesManager/TermsAndPoliciesManagerScreen'
              ).then(({ TermsAndPoliciesManagerScreen }) => ({
                default: TermsAndPoliciesManagerScreen,
              })),
            ),
            exact: true,
            isPrivate: true,
          },
        ]
      : []),
    ...(userHasMarketingAccess
      ? [
          {
            path: '/marketing',
            component: lazy(() =>
              import('@/presentation/screens/Marketing/MarketingScreen').then(
                ({ MarketingScreen }) => ({
                  default: MarketingScreen,
                }),
              ),
            ),
            exact: true,
            isPrivate: true,
          },
        ]
      : []),
    ...(userHasRegisteredContactsAccess
      ? [
          {
            path: '/contact-manager',
            component: lazy(() =>
              import(
                '@/presentation/screens/ContactManager/ContactManagerScreen'
              ).then(({ ContactManagerScreen }) => ({
                default: ContactManagerScreen,
              })),
            ),
            exact: true,
            isPrivate: true,
          },
        ]
      : []),
    ...(userHasUserAccessAccess
      ? [
          {
            path: '/user-access',
            component: lazy(() =>
              import(
                '@/presentation/screens/ProfileCustomer/UserAccess/UserAccessScreen'
              ).then(({ UserAccessScreen }) => ({
                default: UserAccessScreen,
              })),
            ),
            exact: true,
            isPrivate: true,
          },
          {
            path: '/register-user',
            component: lazy(() =>
              import(
                '@/presentation/screens/ProfileCustomer/RegisterUser/RegisterUserScreen'
              ).then(({ RegisterUserScreen }) => ({
                default: RegisterUserScreen,
              })),
            ),
            exact: true,
            isPrivate: true,
          },
        ]
      : []),
    {
      path: '/my-profile',
      component: lazy(() =>
        import(
          '@/presentation/screens/ProfileCustomer/MyProfile/MyProfileScreen'
        ).then(({ MyProfileScreen }) => ({ default: MyProfileScreen })),
      ),
      exact: true,
      isPrivate: true,
    },
    {
      path: '/login',
      exact: true,
      isPrivate: false,
      component: LoginScreen,
    },
    {
      path: '/request-access',
      component: lazy(() =>
        import('@/presentation/screens/RequestAccess/RequestAccessScreen').then(
          ({ RequestAccessScreen }) => ({
            default: RequestAccessScreen,
          }),
        ),
      ),
      exact: true,
      isPrivate: false,
    },
    {
      path: '/contact-al',
      component: lazy(() =>
        import('@/presentation/screens/Contact/ContactScreen').then(
          ({ ContactScreen }) => ({
            default: ContactScreen,
          }),
        ),
      ),
      exact: true,
      isPrivate: false,
    },
  ]

  useEffect(() => {
    HttpClientAuthorizedConfigRules.setCountry(userCountry)
  }, [userCountry])

  return {
    isAdmin,
    adminHomePath,
    appRoutes,
    isUserAuthenticated,
  }
}
