import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { translate } from '@/application/utils/Translate/TranslateUtil'
import { virtualPathUtil } from '@/application/utils/VirtualPath/VirtualPathUtil'
import { IBreadcrumbPath } from '@codebox-team/web/components'

const routes: Record<string, IBreadcrumbPath> = {
  home: {
    link: '',
    label: 'Home',
  },
  addresses: {
    link: 'addresses',
    label: translate.t('screens.addresses.title'),
  },
  cart: {
    link: 'cart',
    label: translate.t('general.order_your_gas'),
  },
  orders: {
    link: 'orders',
    label: translate.t('screens.home.orders.title'),
  },
  financial: {
    link: 'financial',
    label: translate.t('screens.home.financial.title'),
  },
  contact: {
    link: 'contact',
    label: translate.t('screens.home.contact.title'),
  },
  terms: {
    link: 'terms-and-policies',
    label: translate.t('components.header_menu.terms_policies'),
  },
  termsManager: {
    link: 'terms-and-policies-manager',
    label: translate.t('components.header_menu.terms_policies'),
  },
  feedback: {
    link: 'feedback',
    label: translate.t('containers.feedback.title'),
  },
  stock: {
    link: 'stock',
    label: translate.t('general.stock'),
  },
  managerRegistration: {
    link: 'manager-registration',
    label: translate.t('screens.profile_manager.manager_registration.title'),
  },
  params: {
    link: 'params',
    label: translate.t('screens.params.title'),
  },
  marketing: {
    link: 'marketing',
    label: translate.t('screens.marketing.title'),
  },
  contactManager: {
    link: 'contact-manager',
    label: translate.t('screens.contact_manager.title'),
  },
  businessGroups: {
    link: 'business-groups',
    label: translate.t('screens.profile_manager.business_groups.title'),
  },
  businessGroupsRegistration: {
    link: 'business-groups-registration',
    label: translate.t('screens.profile_manager.business_groups.registration'),
  },
  businessGroupDetail: {
    link: 'business-group-detail',
    label: '',
  },
  userAccess: {
    link: 'user-access',
    label: translate.t('screens.profile_customer.manage_access.title'),
  },
  registerUser: {
    link: 'register-user',
    label: translate.t('screens.profile_customer.register_user.title'),
  },
  myProfile: {
    link: 'my-profile',
    label: translate.t('screens.profile_customer.my_profile.title'),
  },
}

const routesMappedToBreadcrumbs: Record<string, IBreadcrumbPath[]> = {
  [routes.home.link]: [
    {
      label: routes.home.label,
      link: routes.home.link,
    },
  ],
  [routes.addresses.link]: [
    {
      label: routes.home.label,
      link: routes.home.link,
    },
    {
      label: routes.addresses.label,
      link: routes.addresses.link,
    },
  ],
  [routes.cart.link]: [
    {
      label: routes.home.label,
      link: routes.home.link,
    },
    {
      label: routes.cart.label,
      link: routes.cart.link,
    },
  ],
  [routes.orders.link]: [
    {
      label: routes.home.label,
      link: routes.home.link,
    },
    {
      label: routes.orders.label,
      link: routes.orders.link,
    },
  ],
  [routes.financial.link]: [
    {
      label: routes.home.label,
      link: routes.home.link,
    },
    {
      label: routes.financial.label,
      link: routes.financial.link,
    },
  ],
  [routes.contact.link]: [
    {
      label: routes.home.label,
      link: routes.home.link,
    },
    {
      label: routes.contact.label,
      link: routes.contact.link,
    },
  ],
  [routes.terms.link]: [
    {
      label: routes.terms.label,
      link: routes.terms.link,
    },
  ],
  [routes.termsManager.link]: [
    {
      label: routes.termsManager.label,
      link: routes.termsManager.link,
    },
  ],
  [routes.feedback.link]: [
    {
      label: routes.home.label,
      link: routes.home.link,
    },
    {
      label: routes.feedback.label,
      link: routes.feedback.link,
    },
  ],
  [routes.stock.link]: [
    {
      label: routes.home.label,
      link: routes.home.link,
    },
    {
      label: routes.stock.label,
      link: routes.stock.link,
    },
  ],
  [routes.managerRegistration.link]: [
    {
      label: routes.managerRegistration.label,
      link: routes.managerRegistration.link,
    },
  ],
  [routes.businessGroups.link]: [
    {
      label: routes.businessGroups.label,
      link: routes.businessGroups.link,
    },
  ],
  [routes.params.link]: [
    {
      label: routes.params.label,
      link: routes.params.link,
    },
  ],
  [routes.marketing.link]: [
    {
      label: routes.marketing.label,
      link: routes.marketing.link,
    },
  ],
  [routes.contactManager.link]: [
    {
      label: routes.contactManager.label,
      link: routes.contactManager.link,
    },
  ],
  [routes.businessGroupsRegistration.link]: [
    {
      label: routes.businessGroups.label,
      link: routes.businessGroups.link,
    },
    {
      label: routes.businessGroupsRegistration.label,
      link: routes.businessGroupsRegistration.link,
    },
  ],
  [routes.businessGroupDetail.link]: [
    {
      label: routes.businessGroups.label,
      link: routes.businessGroups.link,
    },
  ],
  [routes.userAccess.link]: [
    {
      label: routes.home.label,
      link: routes.home.link,
    },
    {
      label: routes.userAccess.label,
      link: routes.userAccess.link,
    },
  ],
  [routes.registerUser.link]: [
    {
      label: routes.home.label,
      link: routes.home.link,
    },
    {
      label: routes.registerUser.label,
      link: routes.registerUser.link,
    },
  ],
  [routes.myProfile.link]: [
    {
      label: routes.home.label,
      link: routes.home.link,
    },
    {
      label: routes.myProfile.label,
      link: routes.myProfile.link,
    },
  ],
}

export const useBreadcrumbsByPathName = (): IBreadcrumbPath[] => {
  const history = useHistory()

  return useMemo(() => {
    const currentPathName = history.location.pathname.split('/')[1] ?? ''

    return (
      routesMappedToBreadcrumbs[currentPathName]?.map(breadcrumb => ({
        ...breadcrumb,
        link: virtualPathUtil(breadcrumb.link),
      })) || []
    )
  }, [history.location.pathname])
}
