import { FirebaseConstants } from '@customerportal/core'

import firebase from 'firebase'

export class Firebase {
  public readonly app: firebase.app.App

  constructor() {
    this.app = firebase.initializeApp(FirebaseConstants.firebaseConfig)
  }

  public analytics() {
    firebase.analytics(this.app)
  }
}

export const FirebaseConfig = new Firebase()
