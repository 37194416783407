import React from 'react'

import { StyledCardComponent } from '@/presentation/components/Forms/AuthForm/AuthFormComponent.styles'
import { IAuthFormProps } from '@/presentation/components/Forms/AuthForm/AuthFormComponent.types'
import { BoxComponent } from '@codebox-team/web/components'

export const AuthFormComponent: React.FC<IAuthFormProps> = ({
  title,
  description,
  children,
}) => (
  <StyledCardComponent>
    <BoxComponent
      component="h1"
      color="primary.dark"
      textAlign="center"
      fontSize={20}
      fontWeight={500}
      lineHeight="120%"
      marginBottom={2}>
      {title}
    </BoxComponent>

    <BoxComponent
      component="h2"
      color="secondary.main"
      textAlign="center"
      fontSize={18}
      fontWeight={400}
      lineHeight="120%"
      marginBottom={2}>
      {description}
    </BoxComponent>

    {children}
  </StyledCardComponent>
)
