import React from 'react'

import { translate } from '@/application/utils/Translate/TranslateUtil'
import CartIcon from '@/presentation/assets/icons/cart-icon.svg'
import { ImageComponent } from '@/presentation/components/Data/Image/ImageComponent'
import { MoneyComponent } from '@/presentation/components/Data/Money/MoneyComponent'
import { TotalValueComponent } from '@/presentation/components/Data/TotalValue/TotalValueComponent'
import { useCartSummaryRules } from '@/presentation/components/Navigation/CartSummary/CartSummaryComponent.rules'
import {
  StyledCartSummaryButtonWrapper,
  StyledCartSummaryIconWrapper,
  StyledCartSummaryWrapper,
} from '@/presentation/components/Navigation/CartSummary/CartSummaryComponent.styles'
import { ICartSummaryProps } from '@/presentation/components/Navigation/CartSummary/CartSummaryComponent.types'
import { themeOverrides } from '@/presentation/theme/variables'
import {
  BadgeComponent,
  BoxComponent,
  ButtonComponent,
  IconButtonComponent,
} from '@codebox-team/web/components'

export const CartSummaryComponent: React.FC<ICartSummaryProps> = ({
  cartProducts,
}) => {
  const {
    anchorEl,
    cartProductsAdapt,
    cartQuantityItems,
    getItemLabel,
    handleToggleCartSummary,
    handleClose,
    handleNavigateCart,
    totalValue,
  } = useCartSummaryRules(cartProducts)

  return (
    <BoxComponent data-testid="Cart summary wrapper">
      <IconButtonComponent
        testID="Toggle cart summary button"
        onClick={handleToggleCartSummary}
        disabled={!cartProductsAdapt}>
        <BadgeComponent
          testID="Cart quantity badge"
          badgeContent={cartQuantityItems}
          color="error">
          <CartIcon />
        </BadgeComponent>
      </IconButtonComponent>

      {!!anchorEl && (
        <StyledCartSummaryWrapper
          id="cart-summary-menu"
          anchorEl={anchorEl}
          open
          classes={{ paper: 'cart__summary__wrapper' }}
          onClose={handleClose}>
          <BoxComponent display="flex" flexDirection="column">
            <BoxComponent
              borderBottom={`1px solid ${themeOverrides.palette.primary.main}`}
              component="span"
              fontSize={16}
              fontWeight="bold"
              color={themeOverrides.palette.primary.dark}
              padding="16px"
              textAlign="center">
              {translate.t('components.navigation.cart_summary.title')}
            </BoxComponent>

            {totalValue > 0 && (
              <BoxComponent
                bgcolor={themeOverrides.palette.secondary['100']}
                borderBottom={`1px solid ${themeOverrides.palette.primary.main}`}
                color={themeOverrides.palette.primary.main}
                display="flex"
                fontSize={14}
                maxWidth="312px"
                paddingX="16px"
                paddingY="8px"
                testID="Included costs"
                textAlign="center">
                {translate.t('containers.cart.cart_edit.included_costs')}
              </BoxComponent>
            )}

            <BoxComponent display="flex" flexDirection="column">
              <BoxComponent
                display="flex"
                flexDirection="column"
                gap="16px"
                maxHeight="400px"
                overflow="overlay"
                padding="16px">
                {cartProductsAdapt?.map(product => (
                  <BoxComponent
                    display="flex"
                    flexDirection="column"
                    gap="8px"
                    key={product.productId}>
                    <BoxComponent
                      alignItems="center"
                      display="flex"
                      flexDirection="row"
                      gap="16px">
                      <StyledCartSummaryIconWrapper>
                        {!!product.imageBase64 ? (
                          <img
                            src={`data:image/jpeg;base64,${product.imageBase64}`}
                            alt="Product icon"
                          />
                        ) : (
                          <ImageComponent src="assets/img/default-product-img.png" />
                        )}
                      </StyledCartSummaryIconWrapper>
                      <BoxComponent
                        color={themeOverrides.palette.primary.dark}
                        component="span"
                        fontSize={16}
                        fontWeight="bold"
                        textTransform="capitalize">
                        {product.productTitle}
                      </BoxComponent>
                    </BoxComponent>

                    {product.items.map(item => (
                      <BoxComponent
                        alignItems="center"
                        color={themeOverrides.palette.secondary.main}
                        component="span"
                        display="flex"
                        fontSize={14}
                        justifyContent="space-between"
                        key={item.code}
                        paddingLeft="46px">
                        <BoxComponent
                          alignItems="center"
                          component="span"
                          display="flex"
                          gap="4px">
                          <BoxComponent
                            color={themeOverrides.palette.primary.dark}
                            fontSize="16px"
                            component="span">
                            •
                          </BoxComponent>
                          <BoxComponent component="span">
                            {getItemLabel({
                              description: item.description,
                              quantity: item.quantity,
                            })}
                          </BoxComponent>
                        </BoxComponent>

                        {item.totalValue > 0 && (
                          <MoneyComponent
                            language="pt-BR"
                            testID="Item total value"
                            value={item.totalValue}
                          />
                        )}
                      </BoxComponent>
                    ))}
                  </BoxComponent>
                ))}
              </BoxComponent>
            </BoxComponent>
          </BoxComponent>
          <StyledCartSummaryButtonWrapper>
            {totalValue > 0 && (
              <TotalValueComponent
                testID="Cart total value"
                value={totalValue}
              />
            )}

            <ButtonComponent
              onClick={() => {
                handleNavigateCart()
                handleClose()
              }}
              variant="contained"
              fullWidth
              testID="Navigate cart button">
              {translate.t('components.navigation.cart_summary.go_to_cart')}
            </ButtonComponent>
          </StyledCartSummaryButtonWrapper>
        </StyledCartSummaryWrapper>
      )}
    </BoxComponent>
  )
}
