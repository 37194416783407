import { ArlindoComponent } from '@/presentation/components/Data/Arlindo/ArlindoComponent'
import { BoxComponent } from '@codebox-team/web/components'
import { StyleSheet } from '@codebox-team/web/utils'

export const StyledLoginScreenWrapper = StyleSheet.create(BoxComponent)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 8px 16px;
`

export const StyledArlindoComponent = StyleSheet.create(ArlindoComponent)`
  position: relative;
  top: 20px;
`
