import { ImageComponent } from '@/presentation/components/Data/Image/ImageComponent'
import { StyleSheet } from '@codebox-team/web/utils'

export const StyledCheckboxIcon = StyleSheet.create('div')`
  width: 16px;
  height: 16px;
  border: 1px solid ${({ theme }) => theme.palette.secondary.light};
  border-radius: 2px;
`

export const StyledCheckboxCheckedIcon = StyleSheet.create('div')`
  width: 16px;
  height: 16px;
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledCheckboxCheckedIconInside = StyleSheet.create(
  ImageComponent,
)`
  display: block;
  width: 9px;
  height: 6px;
`
