import { makeStyles } from '@codebox-team/web/components'

export const useTotalValueComponentStyles = makeStyles()(({ palette }) => ({
  container: {
    color: palette.primary.main,
    display: 'flex',
    fontWeight: 700,
    gap: '4px',
    justifyContent: 'space-between',
  },
  filler: {
    display: 'block',
    flex: 1,
    letterSpacing: '4px',
    maxWidth: '100%',
    overflowX: 'hidden',
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
}))
