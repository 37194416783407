import React from 'react'

import { ImageComponent } from '@/presentation/components/Data/Image/ImageComponent'
import { StyledDialogComponent } from '@/presentation/components/Feedback/ActivityIndicator/ActivityIndicatorComponent.styles'
import { IActivityIndicatorProps } from '@/presentation/components/Feedback/ActivityIndicator/ActivityIndicatorComponent.types'
import { BoxComponent } from '@codebox-team/web/components'

export const ActivityIndicatorComponent: React.FC<IActivityIndicatorProps> = ({
  messages,
  isOpen,
  testID,
}) => (
  <StyledDialogComponent
    $noMessage={!messages}
    open={isOpen}
    fullScreen={false}
    classes={{ paper: 'paper' }}
    testID={testID}>
    <ImageComponent
      src="assets/img/ongoing.gif"
      alt="Icon"
      width={83}
      height={83}
      data-testid="Icon"
    />

    {messages?.map((message, index) => (
      <BoxComponent
        key={index}
        component="p"
        color="primary.dark"
        textAlign="center"
        fontSize="body2.fontSize"
        fontWeight={400}
        lineHeight="120%"
        marginTop={index === 0 ? 2 : 1}>
        {message}
      </BoxComponent>
    ))}
  </StyledDialogComponent>
)
