import { StyleSheet } from '@codebox-team/web/utils'

export const StyledAppLayoutPrivateProviderWrapper = StyleSheet.create('div')`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.palette.secondary['100']};
  padding-top: 48px;
`

export const StyledPrivateContentWrapper = StyleSheet.create('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.palette.background.paper};
  align-items: center;
  padding: 24px 16px 60px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding-top: 16px;
  }
`

export const StyledPrivateMainContentWrapper = StyleSheet.create('div')`
  flex-grow: 1;
  width: 100%;
  max-width: 1000px;

  .menu {
    max-width: 676px;
    margin: 32px auto;
  }

  .divider {
    background-color: ${({ theme }) => theme.palette.secondary.light};
    margin-bottom: 32px;
  }
`
