import React, { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { translate } from '@/application/utils/Translate/TranslateUtil'
import AccountTreeIcon from '@/presentation/assets/icons/account-tree-icon.svg'
import CampaignIcon from '@/presentation/assets/icons/campaign-icon.svg'
import ContactIcon from '@/presentation/assets/icons/contact-icon.svg'
import DomainBlueIcon from '@/presentation/assets/icons/domain-blue-icon.svg'
import StockIcon from '@/presentation/assets/icons/fact-check-icon.svg'
import FeedbackIcon from '@/presentation/assets/icons/feedback-icon.svg'
import FinancialIcon from '@/presentation/assets/icons/financial-icon.svg'
import GasIcon from '@/presentation/assets/icons/gas-icon.svg'
import LocationIcon from '@/presentation/assets/icons/location-icon.svg'
import OrderIcon from '@/presentation/assets/icons/order-icon.svg'
import SettingsIcon from '@/presentation/assets/icons/settings-icon.svg'
import TermsIcon from '@/presentation/assets/icons/terms-icon.svg'
import {
  EHeaderMenuConfig,
  IHeaderMenuItem,
} from '@/presentation/components/Navigation/HeaderMenu/HeaderMenuComponent.types'
import { usePagePermission } from '@/presentation/hooks/UsePagePermission/UsePagePermission'
import { useSelector } from '@codebox-team/redux'
import { formatCNPJ, formatCPF } from '@codebox-team/tools/Formatters'
import {
  ERegionIdentifiers,
  selectUserCountry,
  documentARFormatter,
  EUserRoles,
  selectUserDetails,
} from '@customerportal/core'

export const useHeaderMenuComponentRules = () => {
  const history = useHistory()

  const userDetails = useSelector(selectUserDetails)

  const userHasStockAccess = usePagePermission(EUserRoles.PortalStock)
  const userHasManagerRegistrationAccess = usePagePermission(
    EUserRoles.PortalManagerRegistration,
  )
  const userHasBusinessGroupsAccess = usePagePermission(
    EUserRoles.PortalBusinessGroups,
  )
  const userHasUserAccessAccess = usePagePermission(EUserRoles.PortalUserAccess)
  const userHasFinancialAccess = usePagePermission(EUserRoles.PortalFinancial)
  const userHasOrdersCreationAccess = usePagePermission(
    EUserRoles.PortalOrdersCreation,
  )
  const userHasOrdersTrackingAccess = usePagePermission(
    EUserRoles.PortalOrdersTracking,
  )
  const userHasRegisteredContactsAccess = usePagePermission(
    EUserRoles.PortalRegisteredContacts,
  )
  const userHasParametersAccess = usePagePermission(EUserRoles.PortalParameters)
  const userHasTermsAndPoliciesAccess = usePagePermission(
    EUserRoles.PortalTermsAndPolicies,
  )
  const userHasMarketingAccess = usePagePermission(EUserRoles.PortalMarketing)

  const userIsCustomer = !userDetails?.isAdmin

  const isCommonCustomer = userIsCustomer && !userHasUserAccessAccess

  const userCountry = useSelector(selectUserCountry)

  const isCountryAR = userCountry === ERegionIdentifiers.AR

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleToggleMenu = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget)

  const handleClose = () => setAnchorEl(null)

  const handleGoToPage = (link: string) => {
    handleClose()
    history.push(link)
  }

  const firstMenuList = useMemo<IHeaderMenuItem[]>(
    () =>
      [
        userHasManagerRegistrationAccess && {
          text: translate.t(
            'screens.profile_manager.manager_registration.title',
          ),
          link: '/manager-registration',
          svgIcon: SettingsIcon,
        },
        userHasBusinessGroupsAccess && {
          text: translate.t('screens.profile_manager.business_groups.title'),
          link: '/business-groups',
          svgIcon: DomainBlueIcon,
        },
        userHasParametersAccess && {
          text: translate.t('screens.params.title'),
          link: '/params',
          svgIcon: AccountTreeIcon,
        },
        userHasTermsAndPoliciesAccess && {
          text: translate.t('components.header_menu.terms_policies'),
          link: '/terms-and-policies-manager',
          svgIcon: TermsIcon,
        },
        userHasMarketingAccess && {
          text: translate.t('screens.marketing.title'),
          link: '/marketing',
          svgIcon: CampaignIcon,
        },
        userHasUserAccessAccess && {
          text: translate.t('screens.profile_customer.manage_access.title'),
          link: '/user-access',
          svgIcon: SettingsIcon,
        },
        isCommonCustomer && {
          text: translate.t('screens.profile_customer.my_profile.title'),
          link: '/my-profile',
          svgIcon: SettingsIcon,
        },
      ].filter(Boolean),
    [
      userHasBusinessGroupsAccess,
      userHasTermsAndPoliciesAccess,
      userHasParametersAccess,
      userHasMarketingAccess,
      userHasUserAccessAccess,
      userHasManagerRegistrationAccess,
      isCommonCustomer,
    ],
  )

  const secondMenuList = useMemo<IHeaderMenuItem[]>(
    () =>
      [
        userHasOrdersCreationAccess && {
          text: translate.t('components.header_menu.order_gas'),
          link: '/',
          svgIcon: GasIcon,
        },
        userHasOrdersTrackingAccess && {
          text: translate.t('components.header_menu.orders'),
          link: '/orders',
          svgIcon: OrderIcon,
        },
        userHasStockAccess && {
          text: translate.t('general.stock'),
          link: '/stock',
          svgIcon: StockIcon,
        },
        userHasFinancialAccess && {
          text: translate.t('components.header_menu.financial'),
          link: '/financial',
          svgIcon: FinancialIcon,
        },
        userIsCustomer && {
          text: translate.t('components.header_menu.contact'),
          link: '/contact',
          svgIcon: ContactIcon,
        },
        userIsCustomer && {
          text: translate.t('components.header_menu.delivery_address'),
          link: '/addresses',
          svgIcon: LocationIcon,
        },
        userIsCustomer && {
          text: translate.t('components.header_menu.terms_policies'),
          link: '/terms-and-policies',
          svgIcon: TermsIcon,
        },
        userHasRegisteredContactsAccess && {
          text: translate.t('screens.contact_manager.title'),
          link: '/contact-manager',
          svgIcon: ContactIcon,
        },
        userIsCustomer && {
          text: translate.t('general.rate_experience'),
          link: '/feedback',
          svgIcon: FeedbackIcon,
        },
      ].filter(Boolean),
    [
      userHasFinancialAccess,
      userHasOrdersCreationAccess,
      userHasOrdersTrackingAccess,
      userHasRegisteredContactsAccess,
      userHasStockAccess,
      userIsCustomer,
    ],
  )

  const documentFormat = useCallback(
    (document: string) => {
      if (!document) {
        return ''
      }

      if (isCountryAR) {
        return documentARFormatter(document)
      }

      return document.length > EHeaderMenuConfig.cpfLength
        ? formatCNPJ(document)
        : formatCPF(document)
    },
    [isCountryAR],
  )

  return {
    handleGoToPage,
    anchorEl,
    handleToggleMenu,
    handleClose,
    documentFormat,
    userIsCustomer,
    firstMenuList,
    secondMenuList,
  }
}
