import { LinkComponent } from '@codebox-team/web/components'
import { StyleSheet } from '@codebox-team/web/utils'
import { COLORS } from '@customerportal/core'

export const StyledDueInvoicesContainer = StyleSheet.create('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const StyledLink = StyleSheet.create(LinkComponent)`
  color: ${COLORS.blue};
  font-size: 14px;
  font-weight: 500;
`

export const StyledLinkContainer = StyleSheet.create('div')`
  display: flex;
  justify-content: flex-end;
`

export const StyledDueInvoicesMessage = StyleSheet.create('div')`
  color: ${COLORS.gray};
  font-size: 14px;
`

export const StyledDueInvoicesTitle = StyleSheet.create('div')`
  color: ${({ theme }) => theme.palette.error.main};
  font-weight: 500;
  padding-top: 10px;
`
