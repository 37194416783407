import { StyleSheet } from '@codebox-team/web/utils'

export const StyledMainMenuComponentWrapper = StyleSheet.create('div')`
  display: flex;
  justify-content: center;

  .nav_link {
    width: 100%;
    height: 56px;
    display: flex;
    padding: 2px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background: ${({ theme }) => theme.palette.common.white};
    box-shadow: ${({ theme }) => theme.palette.shadow.main};
    border: 2px solid transparent;
    text-decoration: none;

    :not(:last-child) {
      margin-right: 32px;
    }

    p {
      color: ${({ theme }) => theme.palette.primary.main};
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 120%;
      margin-left: 12px;
      margin-right: 12px;
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
      flex-direction: column;

      :not(:last-child) {
        margin-right: 8px;
      }

      p {
        margin-left: 0px;
        margin-top: 8px;
      }
    }
  }

  .nav_link.active {
    border: 2px solid ${({ theme }) => theme.palette.primary.main};

    p {
      font-weight: 600;
    }
  }
`
