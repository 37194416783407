import { useState } from 'react'

import { TInputTextProps } from '@codebox-team/web/components'

export const useInputTextComponentRules = (
  props: TInputTextProps,
): TInputTextProps => {
  const [value, setValue] = useState<string>(String(props?.value))
  const [isShrink, setIsShrink] = useState<boolean>(!!props?.value)

  return {
    ...props,
    onFocus: () => setIsShrink(true),
    onBlur: () => !value && setIsShrink(false),
    onChange: (
      e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => {
      setValue(e.target.value)
      props.onChange?.(e)
    },
    InputLabelProps: { shrink: isShrink },
  }
}
