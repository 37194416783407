import React from 'react'

import { translate } from '@/application/utils/Translate/TranslateUtil'
import {
  StyledDueInvoicesContainer,
  StyledDueInvoicesMessage,
  StyledDueInvoicesTitle,
  StyledLink,
  StyledLinkContainer,
} from '@/presentation/components/Feedback/DueInvoices/DueInvoicesComponent.styles'
import { IDueInvoicesComponentProps } from '@/presentation/components/Feedback/DueInvoices/DueInvoicesComponent.types'

export const DueInvoicesComponent: React.FC<IDueInvoicesComponentProps> = ({
  action,
  title,
  subTitle,
}) => (
  <StyledDueInvoicesContainer>
    <StyledDueInvoicesTitle>{title}</StyledDueInvoicesTitle>

    <StyledDueInvoicesMessage>{subTitle}</StyledDueInvoicesMessage>

    <StyledLinkContainer>
      <StyledLink onClick={action}>
        {translate.t('components.due_invoices.check_payments')}
      </StyledLink>
    </StyledLinkContainer>
  </StyledDueInvoicesContainer>
)
