import React from 'react'

import packageJson from '@/../package.json'
import { TScreenProps } from '@/application/routes/Route.types'
import { translate } from '@/application/utils/Translate/TranslateUtil'
import { LoginFormComponent } from '@/presentation/components/Forms/LoginForm/LoginFormComponent'
import { VerificationCodeFormComponent } from '@/presentation/components/Forms/VerificationCodeForm/VerificationCodeFormComponent'
import { useLoginScreenRules } from '@/presentation/screens/Login/LoginScreen.rules'
import {
  StyledArlindoComponent,
  StyledLoginScreenWrapper,
} from '@/presentation/screens/Login/LoginScreen.styles'
import { useSelector } from '@codebox-team/redux'
import { BoxComponent } from '@codebox-team/web/components'
import { selectUser } from '@customerportal/core'

export const LoginScreen: React.FC<TScreenProps<'login'>> = props => {
  const {
    login,
    region,
    verificationCodeRequestStatus,
    verificationCodeValidationStatus,
  } = useSelector(selectUser)

  const {
    requestVerificationCode,
    submitVerificationCode,
    onGetSupportHandler,
  } = useLoginScreenRules(props)

  return (
    <StyledLoginScreenWrapper>
      <StyledArlindoComponent type="no_trunk" />

      {!!login && !!region ? (
        <VerificationCodeFormComponent
          onSubmitCode={submitVerificationCode}
          onRequestNewCode={requestVerificationCode}
          codeSubmissionStatus={verificationCodeValidationStatus}
          newCodeRequestStatus={verificationCodeRequestStatus}
          onGetSupport={onGetSupportHandler}
          contact={login}
          testID="Verification code form"
        />
      ) : (
        <LoginFormComponent
          onSubmitLoginForm={requestVerificationCode}
          loginFormSubmissionStatus={verificationCodeRequestStatus}
          onGetSupport={onGetSupportHandler}
          testID="Login form"
        />
      )}

      <BoxComponent
        component="p"
        color="primary.dark"
        fontSize="14px"
        fontWeight={700}
        lineHeight="120%"
        marginY="20px">
        {translate.t('general.version')} {packageJson.version}
      </BoxComponent>
    </StyledLoginScreenWrapper>
  )
}
