import React from 'react'

import { ImageComponent } from '@/presentation/components/Data/Image/ImageComponent'
import {
  StyledMessage,
  StyledTitle,
  StyledTopContent,
} from '@/presentation/components/Feedback/Toast/ToastComponent.styles'
import { IToastProps } from '@/presentation/components/Feedback/Toast/ToastComponent.types'

export const ToastComponent: React.FC<IToastProps> = ({ title, message }) => (
  <div>
    <StyledTopContent>
      <StyledTitle data-testid="Toast title">{title}</StyledTitle>
      <ImageComponent
        src="assets/img/worker-blinking.png"
        alt="Icon"
        width={43}
        height={43}
        data-testid="Icon"
      />
    </StyledTopContent>
    <StyledMessage data-testid="Toast message">{message}</StyledMessage>
  </div>
)
