import { ImageComponent } from '@/presentation/components/Data/Image/ImageComponent'
import { ButtonComponent, DialogComponent } from '@codebox-team/web/components'
import { StyleSheet } from '@codebox-team/web/utils'

export const StyledDialogComponent = StyleSheet.create(DialogComponent)({
  ['.paper']: {
    padding: '38px 38px !important',

    ['> div']: {
      maxWidth: '357px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
})

export const StyledIconComponent = StyleSheet.create(ImageComponent)`
  margin-bottom: 8px;
`

export const StyledButtonComponent = StyleSheet.create(ButtonComponent)<{
  index: number
}>(({ index }) => ({
  marginTop: index > 0 ? '8px' : '0px',
}))
