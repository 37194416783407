import { getCurrentLocale } from '@/application/utils/Translate/TranslateUtil'
import { TokenConfig } from '@/infra/Token/TokenConfig'
import {
  applyMiddleware,
  createStore,
  Store,
  persistReducer,
  persistStore,
} from '@codebox-team/redux'
import { createSagaMiddleware } from '@codebox-team/redux-saga'
import {
  HttpClientAuthorizedConfig,
  makeAuthenticationRefreshTokenFactory,
  ReducerConfig,
  SagaConfig,
  TStoreState,
} from '@customerportal/core'
import { composeWithDevTools } from '@redux-devtools/extension'

import storage from 'redux-persist/lib/storage'

export const PersistedReducer = persistReducer(
  {
    key: '@customerPortal',
    storage,
    whitelist: [
      'userStore',
      'financialStore',
      'productsStore',
      'deliveryPointsStore',
    ],
  },
  ReducerConfig,
)

export const SagaMiddleware = createSagaMiddleware()

const middlewares = [SagaMiddleware]

export const store: Store<TStoreState> = createStore(
  PersistedReducer,
  composeWithDevTools(applyMiddleware(...middlewares)),
)

export const persistor = persistStore(store)

SagaMiddleware.run(SagaConfig)

HttpClientAuthorizedConfig.addLocale(getCurrentLocale())
HttpClientAuthorizedConfig.addTokenService(TokenConfig(store))
HttpClientAuthorizedConfig.addTokenRefreshService(
  makeAuthenticationRefreshTokenFactory(),
)
