import { CardComponent } from '@codebox-team/web/components'
import { StyleSheet } from '@codebox-team/web/utils'

export const StyledCardComponent = StyleSheet.create(CardComponent)`
  max-width: 472px;
  border: 0;
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.palette.shadow.main};
  padding: 16px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: 30px 60px;
  }
`
