import React, { useCallback } from 'react'
import { NavLink } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

import { StyledMainMenuComponentWrapper } from '@/presentation/components/Navigation/MainMenu/MainMenuComponent.styles'
import { IMainMenuProps } from '@/presentation/components/Navigation/MainMenu/MainMenuComponent.types'

export const MainMenuComponent: React.FC<IMainMenuProps> = ({
  className,
  testID,
  menus,
}) => {
  const history = useHistory()

  const isActive = useCallback(
    (path: string) => {
      const result =
        history.location.pathname.split('/')[1] === path.split('/')[1] ||
        (history.location.pathname === '/cart' && path === '/') ||
        (history.location.pathname === '/business-groups-registration' &&
          path === '/business-groups') ||
        (history.location.pathname === '/business-group-detail' &&
          path === '/business-groups')

      return result
    },
    [history.location.pathname],
  )

  return (
    <StyledMainMenuComponentWrapper className={className} data-testid={testID}>
      {menus.map(item => (
        <NavLink
          key={item.path}
          to={item.path}
          exact
          className={`nav_link ${isActive(item.path) && 'active'}`}
          data-testid={item.path}>
          {item.icon && <item.icon />}
          <p>{item.label}</p>
        </NavLink>
      ))}
    </StyledMainMenuComponentWrapper>
  )
}
