import React, { useCallback, useEffect, useState } from 'react'

import { translate } from '@/application/utils/Translate/TranslateUtil'
import FeedbackIcon from '@/presentation/assets/icons/feedback-icon.svg'
import {
  StyledMessage,
  StyledTitle,
} from '@/presentation/containers/FeedbackToast/FeedbackToastContainer.styles'
import { useToast } from '@/presentation/hooks/UseToast/UseToast'
import { useDispatch, useSelector } from '@codebox-team/redux'
import { BoxComponent } from '@codebox-team/web/components'
import {
  COLORS,
  selectUserFeedbackDisplayToast,
  setUserFeedbackDisplayToast,
  userFeedbackActionStatusClear,
} from '@customerportal/core'

export const useFeedbackToastContainerRules = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const toast = useToast()
  const dispatch = useDispatch()

  const userFeedbackDisplayToast = useSelector(selectUserFeedbackDisplayToast)

  const onClose = () => {
    setIsOpen(false)
  }

  const onClick = useCallback(() => {
    dispatch(userFeedbackActionStatusClear())
    setIsOpen(true)
  }, [dispatch])

  const handleToast = useCallback(() => {
    const content = (
      <BoxComponent testID="Toast content">
        <BoxComponent display="flex">
          <FeedbackIcon />
          <StyledTitle>{translate.t('general.rate_experience')}</StyledTitle>
        </BoxComponent>

        <StyledMessage data-testid="Toast message">
          {translate.t('containers.feedback.toast.message')}
        </StyledMessage>
      </BoxComponent>
    )

    toast({
      title: translate.t('general.rate_experience'),
      message: translate.t('containers.feedback.toast.message'),
      options: {
        autoClose: false,
        closeButton: true,
        onClick,
        position: 'bottom-right',
        style: {
          padding: 16,
          border: `2px solid ${COLORS.blue}`,
          right: '15px',
          borderRadius: '4px',
        },
      },
      content,
    })

    dispatch(setUserFeedbackDisplayToast(false))
  }, [dispatch, onClick, toast])

  useEffect(() => {
    if (userFeedbackDisplayToast) {
      handleToast()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFeedbackDisplayToast])

  return { isOpen, onClose }
}
