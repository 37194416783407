import {
  IconComponent,
  MenuComponent,
  MenuItemComponent,
} from '@codebox-team/web/components'
import { StyleSheet } from '@codebox-team/web/utils'

export const StyledIconComponent = StyleSheet.create(IconComponent)`
  color: ${({ theme }) => theme.palette.common.white};
`

export const StyledMenuWrapper = StyleSheet.create('div')`
  display: flex;
  align-items: center;
`

export const StyledMenuComponent = StyleSheet.create(MenuComponent)`
  .menu__wrapper {
    top: 72px !important;
    box-shadow: none;
    min-width: 312px;
    background-color: ${({ theme }) => theme.palette.common.white};

    li {
      font-size: 14px;
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    .menu__wrapper {
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: calc(100% - 64px);
      left: 0 !important;
      border-radius: 0;
    }
  }
`

export const StyledSkeletonWrapper = StyleSheet.create('div')`
  width: 312px;
  height: 130px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const StyledUserNameWrapper = StyleSheet.create('div')`
  display: flex;
  padding: 14px 14px 0px;

  img {
    width: 25px;
    margin-right: 8px;
  }

  strong {
    color: ${({ theme }) => theme.palette.primary.dark};
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
    line-height: 120%;
  }
`

export const StyledUserDetailsWrapper = StyleSheet.create('div')`
  width: 100%;
  max-width: 312px;
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.palette.secondary['100']};
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.palette.primary.main};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    max-width: 100%;
  }
`

export const StyledUserDetailContent = StyleSheet.create('div')`
  display: flex;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 14px;
  color: ${({ theme }) => theme.palette.primary.dark};
  font-weight: 400;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  span {
    font-size: 17px;
    margin-right: 5px;
  }

  > p {
    color: ${({ theme }) => theme.palette.primary.main};
    line-height: 120%;
  }
`

export const StyledMenuItemWrapper = StyleSheet.create(MenuItemComponent)`
  padding: 0;

  padding: 4px 16px;

  &:nth-child(2) {
    margin-top: 12px;
  }

  svg,
  p {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const StyledMenuItemIcon = StyleSheet.create('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  margin-right: 8px;
`
