import { COLORS } from '@customerportal/core'

const FONT_SIZE_DEFAULT = 16
const FONT_SIZE_MEDIUM = 14
const FONT_SIZE_SMALL = 10

const FONT_WEIGHT_BOLD = 700
const FONT_WEIGHT_MEDIUM = 500
const FONT_WEIGHT_REGULAR = 400

export const themeOverrides: any = {
  palette: {
    common: {
      white: COLORS.white,
    },
    primary: {
      main: COLORS.blue,
      dark: COLORS.darkBlue,
      light: COLORS.lightBlue,
    },
    secondary: {
      main: COLORS.gray,
      light: COLORS.mediumGray,
      100: COLORS.ice,
    },
    success: {
      main: COLORS.success,
      dark: COLORS.darkGreen,
    },
    error: {
      main: COLORS.danger,
    },
    warning: {
      main: COLORS.warning,
    },
    divider: COLORS.lightGray,
    text: {
      primary: COLORS.black,
      secondary: COLORS.gray,
    },
    shadow: {
      main: '0px 2px 2px 0px rgba(0, 0, 0, 0.07)',
    },
    background: {
      paper: COLORS.paper,
    },
  },
  typography: {
    caption: {
      fontSize: FONT_SIZE_SMALL,
      color: COLORS.black,
    },
    fontFamily: 'Roboto',
    fontSize: FONT_SIZE_DEFAULT,
    body1: {
      fontSize: FONT_SIZE_DEFAULT,
    },
    body2: {
      fontSize: `${FONT_SIZE_MEDIUM}px`,
    },
    h4: {
      fontWeight: FONT_WEIGHT_BOLD,
      fontSize: 32,
      lineHeight: '48px',
    },
    fontWeightMedium: FONT_WEIGHT_MEDIUM,
    fontWeightBold: FONT_WEIGHT_BOLD,
    fontWeightRegular: FONT_WEIGHT_REGULAR,
  },
  components: {
    MuiListItemText: {
      styleOverrides: {
        primary: { fontSize: FONT_SIZE_MEDIUM },
        root: {
          paddingLeft: 10,
          color: COLORS.black,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: COLORS.black,
          minWidth: 20,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: COLORS.lightGray,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          padding: '8px 24px',
          boxShadow: 'none',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '&&& div[role="dialog"] > div': {
            padding: '8px 24px !important',
          },
        },
        paper: {
          boxShadow: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          color: COLORS.white,
          backgroundColor: COLORS.blue,
          transition: '1s ease',
          '&:hover': {
            backgroundColor: COLORS.blue,
            opacity: 0.8,
          },
          ':disabled': {
            color: COLORS.white,
            backgroundColor: COLORS.lightGray,
          },
        },
        outlined: {
          color: COLORS.blue,
          borderColor: COLORS.blue,
          border: '1px solid',
          backgroundColor: COLORS.white,
        },
        root: {
          fontSize: '16px',
          height: 48,
          border: '0px',
          borderRadius: '4px !important',
          padding: '8px 24px 8px 24px',
          fontWeight: FONT_WEIGHT_MEDIUM,
          textTransform: 'none',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: COLORS.gray,
          borderRadius: '4px !important',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: '0 !important',
          '&:hover': {
            backgroundColor: COLORS.white,
          },
        },
        content: { margin: '0 !important' },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'transparent !important',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: FONT_SIZE_MEDIUM,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          background: 'rgba(0,0,0,0.2)',
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'center',
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
}
