import React from 'react'

import { useInputTextComponentRules } from '@/presentation/components/Inputs/InputText/InputTextComponent.rules'
import { StyledInputTextComponent } from '@/presentation/components/Inputs/InputText/InputTextComponent.styles'
import { TInputTextProps } from '@codebox-team/web/components'

export const InputTextComponent: React.FC<TInputTextProps> = props => {
  const rules = useInputTextComponentRules(props)

  //@ts-ignore
  return <StyledInputTextComponent {...rules} />
}
