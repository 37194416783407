import { translate } from '@/application/utils/Translate/TranslateUtil'
import {
  ILoginFormProps,
  TLoginForm,
} from '@/presentation/components/Forms/LoginForm/LoginFormComponent.types'
import { IUseFormProps, Validator } from '@codebox-team/react-hooks'
import { EActionStatus } from '@customerportal/core'

export const loginForm: IUseFormProps<TLoginForm> = {
  defaultValues: { region: '' as TLoginForm['region'], login: '' },
  validationSchema: {
    country: Validator.string().required(),
    region: Validator.string().required(translate.t('form.messages.required')),
    login: Validator.string()
      .required(translate.t('form.messages.required'))
      .min(
        4,
        translate.t('form.messages.min_length', { numberOfCharacters: 4 }),
      ),
    phonePrefix: Validator.string().required(),
  },
}

export const useLoginFormRules = (
  args: Pick<ILoginFormProps, 'loginFormSubmissionStatus'>,
) => {
  const isBusy = args.loginFormSubmissionStatus?.type === EActionStatus.Busy

  const isFailure =
    args.loginFormSubmissionStatus?.type === EActionStatus.Failure

  const isBadRequest =
    args.loginFormSubmissionStatus?.failureReason === 'bad_request'

  const badRequestMessage =
    args.loginFormSubmissionStatus?.message ||
    translate.t('components.login_form.dialog.message')

  const errorMessage = isBadRequest
    ? badRequestMessage
    : translate.t('general.generic_error_message')

  return { isBusy, isFailure, isBadRequest, errorMessage }
}
