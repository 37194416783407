import React, { useEffect, useState } from 'react'

import { translate } from '@/application/utils/Translate/TranslateUtil'
import { ActivityIndicatorComponent } from '@/presentation/components/Feedback/ActivityIndicator/ActivityIndicatorComponent'
import { DialogComponent } from '@/presentation/components/Feedback/Dialog/DialogComponent'
import { AuthFormComponent } from '@/presentation/components/Forms/AuthForm/AuthFormComponent'
import { StyledFormComponent } from '@/presentation/components/Forms/VerificationCodeForm/VerificationCodeFormComponent.styles'
import {
  EVerificationCodeConfig,
  IVerificationCodeFormProps,
} from '@/presentation/components/Forms/VerificationCodeForm/VerificationCodeFormComponent.types'
import { InputTextComponent } from '@/presentation/components/Inputs/InputText/InputTextComponent'
import {
  BoxComponent,
  ButtonComponent,
  DividerComponent,
  IconComponent,
  LinearProgressComponent,
} from '@codebox-team/web/components'
import {
  EActionStatus,
  useVerificationCodeExpiration,
  useVerificationCodeField,
} from '@customerportal/core'

export const VerificationCodeFormComponent: React.FC<
  IVerificationCodeFormProps
> = ({
  onSubmitCode,
  onRequestNewCode,
  codeSubmissionStatus,
  newCodeRequestStatus,
  onGetSupport,
  contact,
  testID,
}) => {
  const [newCodeWasRequested, setNewCodeWasRequested] = useState<boolean>(false)

  const isCodeRefreshed =
    newCodeWasRequested && newCodeRequestStatus?.type === EActionStatus.Success

  const { code, isCodeValid, onChangeCodeValue } = useVerificationCodeField()

  const { codeExpirationProgress, resetCodeExpirationTime, codeHasExpired } =
    useVerificationCodeExpiration()

  const handleRequestNewCode = () => {
    onRequestNewCode()
    setNewCodeWasRequested(true)
    onChangeCodeValue('')
  }

  useEffect(() => {
    if (isCodeRefreshed) {
      resetCodeExpirationTime()
      setNewCodeWasRequested(false)
    }
  }, [isCodeRefreshed, resetCodeExpirationTime])

  const isActiveIndicatorOpen =
    codeSubmissionStatus?.type === EActionStatus.Busy ||
    newCodeRequestStatus?.type === EActionStatus.Busy

  return (
    <div data-testid={testID}>
      <AuthFormComponent
        title={translate.t('components.verification_code_form.title')}
        description={`${translate.t(
          'components.verification_code_form.description',
        )} ${contact}`}>
        <StyledFormComponent>
          <InputTextComponent
            name="code"
            value={code}
            variant="outlined"
            fullWidth
            placeholder={EVerificationCodeConfig.inputMask}
            InputProps={{
              startAdornment: <IconComponent icon="lock_open" />,
            }}
            inputProps={{
              maxLength: 6,
              onChange: (
                event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
              ) => {
                onChangeCodeValue(event.currentTarget.value)
              },
              onKeyPressCapture: event => {
                if (event.code === 'Enter') {
                  onSubmitCode(code)
                  event.preventDefault()
                }
              },
            }}
            classes={{ root: 'container' }}
            testID="Code input field"
          />

          <DividerComponent classes={{ root: 'divider' }} />

          <ButtonComponent
            variant="contained"
            fullWidth
            disabled={!isCodeValid}
            className="submitButton"
            onClick={() => onSubmitCode(code)}
            testID="Submission button">
            {translate.t('general.send')}
          </ButtonComponent>

          <ButtonComponent
            variant="outlined"
            fullWidth
            disabled={!codeHasExpired}
            onClick={handleRequestNewCode}
            testID="New code request button">
            {translate.t('components.verification_code_form.fallback')}
          </ButtonComponent>
        </StyledFormComponent>

        <BoxComponent
          component="p"
          color="secondary.main"
          textAlign="center"
          fontSize="12px"
          fontWeight={400}
          lineHeight="120%"
          marginTop={3}
          marginRight={0}
          marginBottom={1}
          marginLeft={0}>
          {translate.t('components.verification_code_form.message', {
            numberOfMinutes: 5,
          })}
        </BoxComponent>

        <LinearProgressComponent value={codeExpirationProgress} />
      </AuthFormComponent>

      <DialogComponent
        title="Opss!"
        message={codeSubmissionStatus?.message || newCodeRequestStatus?.message}
        isOpen={
          codeSubmissionStatus?.type === EActionStatus.Failure ||
          newCodeRequestStatus?.type === EActionStatus.Failure
        }
        type="failure"
        actions={[
          {
            type: 'primary',
            title: translate.t('general.try_again'),
            onClick: closeDialog => closeDialog(),
            testID: 'Try again action',
          },
          {
            type: 'secondary',
            title: translate.t('general.talk_to_air_liquide'),
            onClick: onGetSupport,
            testID: 'Get support action',
          },
        ]}
        testID="Failure dialog"
      />

      {isActiveIndicatorOpen && (
        <ActivityIndicatorComponent isOpen testID="Activity indicator dialog" />
      )}
    </div>
  )
}
