import { IArlindoProps } from '@/presentation/components/Data/Arlindo/ArlindoComponent.types'

export const getArlindoImageSrc = (type: IArlindoProps['type']): string => {
  const typeMappedToFileName: Record<IArlindoProps['type'], string> = {
    default: 'arlindo',
    no_trunk: 'arlindo-no-trunk',
  }

  const fileName = typeMappedToFileName[type]

  return `assets/img/${fileName}.png`
}
