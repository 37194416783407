import { StyleSheet } from '@codebox-team/web/utils'

export const StyledAppLayoutPublicProviderWrapper = StyleSheet.create('div')`
  height: 100vh;
`

export const StyledPublicHeaderContentWrapper = StyleSheet.create('div')`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledPublicContentWrapper = StyleSheet.create('div')`
  display: flex;
  min-height: 100vh;
  padding-top: 48px;
  background: linear-gradient(
    180deg,
    rgba(0, 120, 233, 0.2) 0%,
    rgba(0, 120, 233, 0) 100%
  );
`
