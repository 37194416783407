import { IFeedbackForm } from '@/presentation/containers/Feedback/FeedbackContainer.types'
import { useForm } from '@codebox-team/react-hooks'
import { useDispatch, useSelector } from '@codebox-team/redux'
import { IInputRatingComponentItem } from '@codebox-team/web/components'
import {
  EActionStatus,
  UserFeedbackNamespace,
  selectUserFeedbackActionStatus,
  userFeedbackActionStatusClear,
  userFeedbackRequest,
} from '@customerportal/core'

export const FEEDBACK_MAX_LENGTH = 500

const feedbackIcons: IInputRatingComponentItem[] = [
  {
    value: UserFeedbackNamespace.ELevel.Poor,
    inactiveImageUrl: 'assets/img/upset.png',
    activeImageUrl: 'assets/img/upset-selected.png',
    width: 40,
    height: 40,
    label: '',
  },
  {
    value: UserFeedbackNamespace.ELevel.Neutral,
    inactiveImageUrl: 'assets/img/neutral.png',
    activeImageUrl: 'assets/img/neutral-selected.png',
    width: 40,
    height: 40,
    label: '',
  },
  {
    value: UserFeedbackNamespace.ELevel.Good,
    inactiveImageUrl: 'assets/img/happy.png',
    activeImageUrl: 'assets/img/happy-selected.png',
    width: 40,
    height: 40,
    label: '',
  },
  {
    value: UserFeedbackNamespace.ELevel.Excellent,
    inactiveImageUrl: 'assets/img/excited.png',
    activeImageUrl: 'assets/img/excited-selected.png',
    width: 40,
    height: 40,
    label: '',
  },
]

export const useFeedbackContainerRules = () => {
  const dispatch = useDispatch()

  const { setValue, watch } = useForm<IFeedbackForm>({
    defaultValues: {
      description: '',
      level: null,
    },
  })

  const { description, level } = watch()

  const userFeedbackStatus = useSelector(selectUserFeedbackActionStatus)

  const isUserFeedbackBusy = userFeedbackStatus?.type === EActionStatus.Busy
  const isUserFeedbackFailure =
    userFeedbackStatus?.type === EActionStatus.Failure
  const isUserFeedbackSuccess =
    userFeedbackStatus?.type === EActionStatus.Success

  const handleSubmitFeedback = () => {
    dispatch(
      userFeedbackRequest({
        description,
        level,
      }),
    )
  }

  const handleFeedbackClear = () => {
    dispatch(userFeedbackActionStatusClear())
  }

  return {
    description,
    feedbackIcons,
    handleSubmitFeedback,
    handleFeedbackClear,
    isUserFeedbackBusy,
    isUserFeedbackFailure,
    isUserFeedbackSuccess,
    level,
    setValue,
  }
}
