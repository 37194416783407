import { useState } from 'react'

import { IRegion } from '@customerportal/core'

export const useRegionAutocompleteComponentRules = () => {
  const [region, setRegion] = useState<IRegion | null>(null)
  const [isShrink, setIsShrink] = useState<boolean>(false)

  return {
    isShrink,
    onFocus: () => setIsShrink(true),
    onBlur: () => !region && setIsShrink(false),
    setRegion,
    region,
  }
}
