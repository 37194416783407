import { FirebaseConstants, envDecryptedCore } from '@customerportal/core'

import firebase from 'firebase'

export class PushNotificationConfig {
  private notification: firebase.Unsubscribe

  public register = async (app: firebase.app.App) => {
    try {
      const messaging = firebase.messaging(app)

      const serviceWorkerRegistration = await navigator.serviceWorker.register(
        `${envDecryptedCore.APP_PATH}/firebase-messaging-sw.js`,
      )

      const token = await messaging.getToken({
        vapidKey: FirebaseConstants.messageConfig.vapidKey,
        serviceWorkerRegistration,
      })

      this.subscribe(messaging)

      return token
    } catch (error) {
      const isNotificationAllowed = Notification.permission === 'granted'

      if (isNotificationAllowed) {
        throw Error(error)
      }
    }
  }

  public subscribe = (messaging: firebase.messaging.Messaging) => {
    this.notification = messaging.onMessage(
      async (payload: firebase.messaging.MessagePayload) => {
        const registrations = await navigator.serviceWorker.getRegistrations()

        if (registrations) {
          const serviceWorker = registrations[0]

          serviceWorker.showNotification(payload.notification.title, {
            body: payload.notification.body,
            icon: '/notification.png',
          })
        }
      },
    )
  }

  public unsubscribe = () => {
    this.notification()
  }
}
