import React from 'react'

import {
  GridComponent,
  BoxComponent,
  SkeletonComponent,
} from '@codebox-team/web/components'
export const FooterComponentSocialMediaSkeleton: React.FC = () => {
  return (
    <BoxComponent
      display="flex"
      flexDirection="row"
      testID="Footer Social Media Skeleton">
      <SkeletonComponent width="48px" height="24px" />

      {[...new Array(4).keys()].map(key => (
        <BoxComponent paddingLeft={2} key={key}>
          <SkeletonComponent width="24px" height="24px" />
        </BoxComponent>
      ))}
    </BoxComponent>
  )
}

export const FooterComponentEnterpriseSkeleton: React.FC = () => {
  return (
    <GridComponent
      item
      xs={12}
      sm={6}
      md={4}
      testID="Footer Enterprise Skeleton">
      <SkeletonComponent width="60%" />
      <SkeletonComponent width="60%" />
    </GridComponent>
  )
}

export const FooterComponentLinkSkeleton: React.FC = () => (
  <>
    {[...new Array(4).keys()].map(key => (
      <BoxComponent paddingLeft={2} key={key} testID="Footer Link Skeleton">
        <SkeletonComponent width="48px" height="24px" />
      </BoxComponent>
    ))}
  </>
)
