import { DialogComponent } from '@codebox-team/web/components'
import { StyleSheet } from '@codebox-team/web/utils'

export const StyledDialogComponent = StyleSheet.create(DialogComponent, {
  shouldForwardProp: prop => prop !== '$noMessage',
})<{ $noMessage?: boolean }>(({ $noMessage }) => ({
  ['.paper']: {
    border: 0,
    maxWidth: '268px',
    padding: `${$noMessage ? '61px ' : '61px 28px'} !important`,

    ['> div']: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
}))
