import { useEffect, useRef, useState } from 'react'

export const useTotalValueComponentRules = () => {
  const [dotCount, setDotCount] = useState<number>(0)

  const fillerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (fillerRef?.current) {
      const letterSpacing = 4
      const fillerWidth = fillerRef.current.clientWidth

      setDotCount(Math.floor(fillerWidth / letterSpacing))
    }
  }, [])

  return { dotCount, fillerRef }
}
