import React from 'react'

import { virtualPathUtil } from '@/application/utils/VirtualPath/VirtualPathUtil'

export const ImageComponent: React.FC<
  React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >
> = props => <img {...props} src={virtualPathUtil(props.src)} />
