import { TokenConfig as TokenConfigService } from '@codebox-team/core'
import { Store } from '@codebox-team/redux'
import {
  EActionStatus,
  TStoreState,
  userClear,
  userValidateVerificationCodeResponse,
} from '@customerportal/core'

export const TokenConfig = (store: Store<TStoreState>) =>
  new TokenConfigService({
    getTokenHandler: () => {
      const credentials = store?.getState?.()?.userStore

      return new Promise(resolve => {
        resolve({
          accessKey: credentials.accessKey || '',
          refreshKey: credentials.refreshKey || '',
        })
      })
    },
    refreshTokenSuccessHandler: params => {
      store.dispatch(
        userValidateVerificationCodeResponse({
          type: EActionStatus.Success,
          accessKey: params.credentials.accessKey,
          refreshKey: params.credentials.refreshKey,
        }),
      )
    },
    refreshTokenFailureHandler: () => store.dispatch(userClear()),
  })
