import { InputRatingComponent } from '@codebox-team/web/components'
import { StyleSheet } from '@codebox-team/web/utils'

export const StyledTitleTextComponent = StyleSheet.create('h2')`
  color: ${({ theme }) => theme.palette.primary.dark};
  font-size: 18px;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  flex-grow: 1;
`

export const StyledFeedbackComponent = StyleSheet.create(InputRatingComponent)`
  margin-top: 8px;
  margin-bottom: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  >label {
    padding-inline: 12px;
  }
`
