import React from 'react'

import { translate } from '@/application/utils/Translate/TranslateUtil'
import ExitIcon from '@/presentation/assets/icons/exit-icon.svg'
import { AirLiquideLogoComponent } from '@/presentation/components/Data/AirLiquideLogo/AirLiquideLogoComponent'
import { ImageComponent } from '@/presentation/components/Data/Image/ImageComponent'
import { useHeaderMenuComponentRules } from '@/presentation/components/Navigation/HeaderMenu/HeaderMenuComponent.rules'
import {
  StyledIconComponent,
  StyledMenuComponent,
  StyledMenuItemIcon,
  StyledMenuItemWrapper,
  StyledMenuWrapper,
  StyledSkeletonWrapper,
  StyledUserDetailContent,
  StyledUserDetailsWrapper,
  StyledUserNameWrapper,
} from '@/presentation/components/Navigation/HeaderMenu/HeaderMenuComponent.styles'
import {
  EHeaderMenuConfig,
  IHeaderMenuProps,
} from '@/presentation/components/Navigation/HeaderMenu/HeaderMenuComponent.types'
import {
  BoxComponent,
  IconButtonComponent,
  IconComponent,
  SkeletonComponent,
} from '@codebox-team/web/components'

export const HeaderMenuComponent: React.FC<IHeaderMenuProps> = ({
  handleLogout,
  userDetails,
  isLoading,
}) => {
  const {
    userIsCustomer,
    firstMenuList,
    secondMenuList,
    anchorEl,
    documentFormat,
    handleClose,
    handleToggleMenu,
    handleGoToPage,
  } = useHeaderMenuComponentRules()

  return (
    <StyledMenuWrapper data-testid="Header Menu Wrapper">
      <BoxComponent marginRight="10px">
        <IconButtonComponent id="header-menu" onClick={handleToggleMenu}>
          <StyledIconComponent icon="menu" />
        </IconButtonComponent>
      </BoxComponent>
      {!!anchorEl && (
        <StyledMenuComponent
          id="header-menu"
          anchorEl={anchorEl}
          open
          classes={{ paper: 'menu__wrapper' }}
          onClose={handleClose}>
          <StyledUserNameWrapper>
            <ImageComponent src="assets/img/worker.png" />
            <strong>
              {translate.t('general.hello')}, {userDetails?.name}
            </strong>
          </StyledUserNameWrapper>

          {firstMenuList.map(({ link, svgIcon: SvgIcon, text }) => (
            <StyledMenuItemWrapper
              onClick={() => handleGoToPage(link)}
              key={text}>
              <StyledMenuItemIcon>
                <SvgIcon />
              </StyledMenuItemIcon>
              <p>{text}</p>
            </StyledMenuItemWrapper>
          ))}

          {userIsCustomer && (
            <div>
              {isLoading ? (
                <StyledSkeletonWrapper data-testid="Header Menu Loading Content">
                  {[...Array(EHeaderMenuConfig.skeletons).keys()].map(item => (
                    <SkeletonComponent
                      key={item}
                      data-testid="Skeleton line"
                      animation="wave"
                      width="100%"
                    />
                  ))}
                </StyledSkeletonWrapper>
              ) : (
                <div>
                  <StyledUserDetailsWrapper>
                    <StyledUserDetailContent>
                      <IconComponent icon="person" color="primary" />
                      <p>{userDetails?.name}</p>
                    </StyledUserDetailContent>
                    <StyledUserDetailContent>
                      <IconComponent
                        icon="store_mall_directory"
                        color="primary"
                      />
                      <p>{userDetails?.identifier}</p>
                    </StyledUserDetailContent>
                    <StyledUserDetailContent>
                      <IconComponent icon="description" color="primary" />
                      <p data-testid="Document formatted text">
                        {documentFormat(userDetails?.document)}
                      </p>
                    </StyledUserDetailContent>
                    <StyledUserDetailContent>
                      <IconComponent icon="place" color="primary" />
                      <p>{userDetails?.address}</p>
                    </StyledUserDetailContent>
                  </StyledUserDetailsWrapper>
                </div>
              )}
            </div>
          )}

          {secondMenuList.map(({ link, svgIcon: SvgIcon, text }) => (
            <StyledMenuItemWrapper
              onClick={() => handleGoToPage(link)}
              key={text}>
              <StyledMenuItemIcon>
                <SvgIcon />
              </StyledMenuItemIcon>
              <p>{text}</p>
            </StyledMenuItemWrapper>
          ))}
          <StyledMenuItemWrapper testID="Logout button" onClick={handleLogout}>
            <StyledMenuItemIcon>
              <ExitIcon />
            </StyledMenuItemIcon>
            <p>{translate.t('components.header_menu.exit_portal')}</p>
          </StyledMenuItemWrapper>
        </StyledMenuComponent>
      )}
      <AirLiquideLogoComponent />
    </StyledMenuWrapper>
  )
}
