import { IActionStatus } from '@customerportal/core'

export interface IVerificationCodeFormProps {
  onSubmitCode: (code: string) => void
  onRequestNewCode: () => void
  codeSubmissionStatus: IActionStatus
  newCodeRequestStatus: IActionStatus
  onGetSupport: () => void
  contact: string
  testID?: string
}

export enum EVerificationCodeConfig {
  inputMask = '___   ___   ___   ___   ___   ___',
}
